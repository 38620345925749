/* eslint-disable max-lines */
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@environment';
import { Store, select } from '@ngrx/store';
import {
  EvacuationDeviceDto,
  FleetManagerFeatures,
  IpstServiceFeatures,
  JobManagerFeatures,
  LoadTypeSettingsState,
  MapManagerFeatures,
  MissionErrorHandlingDefaultsDto,
  OpcuaDeviceResponseModel,
  OrderGatewayFeatures,
  ShutdownModeDto,
  TrafficManagerFeatures,
  VehicleDto,
  WorkingAreaDto,
} from 'core/dtos';

import { AtsActions, GuidString, ToolBarItem } from 'core/models';
import { PermissionService } from 'core/services';
import { ToolbarService } from 'core/services/toolbar.service';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import * as fromOpcuaDevices from 'store-modules/opcua-devices-store';
import * as fromSettings from 'store-modules/settings-store';
import * as fromVehicles from 'store-modules/vehicles-store';
import * as fromRoot from 'store/index';
import { RootState } from 'store/reducers';

@Component({
  selector: 'app-functions-container',
  templateUrl: './functions-container.component.html',
  styleUrls: ['./functions-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunctionsContainerComponent implements OnInit, OnDestroy {
  readonly DEFAULT_ROWS = 25;
  readonly DEFAULT_DEBOUNCE_TIME = 400;
  activeTabId = 'operationTab';
  ngUnsubscribe = new Subject<void>();
  isEditMode$ = of(false);
  searchTerm$: Observable<string> = of('');
  TOOLBAR_ITEMS: ToolBarItem[] = [];
  selectedWorkingArea: GuidString = '';
  isProdMode = environment.config.showDeveloperOptions === 'false';
  canViewChangeLog = false;
  pageNo = 1;
  pageSize = this.DEFAULT_ROWS;
  fetchAllRecords = true;
  fleetSettings$: Observable<FleetManagerFeatures> = of();
  jobManagerSettings$: Observable<JobManagerFeatures> = of();
  loadTypeSettings$: Observable<LoadTypeSettingsState> = of();
  trafficManagerSettings$: Observable<TrafficManagerFeatures> = of();
  mapManagerSettings$: Observable<MapManagerFeatures> = of();
  ipstSettings$: Observable<IpstServiceFeatures> = of();
  orderGatewaySettings$: Observable<OrderGatewayFeatures> = of();
  orderGatewaySettingsLoaded$: Observable<boolean> = of(false);
  fleetSettingsLoaded$: Observable<boolean> = of(false);
  jobManagerSettingsLoaded$: Observable<boolean> = of(false);
  graphManagerSettingsLoaded$: Observable<boolean> = of(false);
  trafficManagerSettingsLoaded$: Observable<boolean> = of(false);
  mapManagerSettingsLoaded$: Observable<boolean> = of(false);
  evacuationDevices$: Observable<EvacuationDeviceDto[]> = of([]);
  allOpcuaDevices$: Observable<OpcuaDeviceResponseModel[]> = of([]);
  currentWorkingArea$: Observable<WorkingAreaDto | undefined> = of();
  ipstSettingsLoaded$: Observable<boolean> = of(false);
  evacuationModeTriggerActive$: Observable<boolean> = of(false);
  allVehicles$: Observable<VehicleDto[]> = of([]);

  graphManagerSetting$ = this.rootStore.pipe(
    select(fromSettings.selectGraphManagerFeatureSettings),

    map(setting => ({
      isToggledOn: setting.settings.enableGraphManager ?? false,
      dateUpdated: setting.graphManagerEnabledUpdatedTimeUtc,
    }))
  );

  isBeginShiftModeEnabledSetting$ = this.rootStore.pipe(
    select(fromSettings.selectGraphManagerFeatureSettings),

    map(setting => ({
      isToggledOn: setting.settings.isBeginShiftModeEnabled ?? false,
      dateUpdated: setting.isBeginShiftModeEnabledUpdatedTimeUtc,
    }))
  );

  constructor(
    private readonly rootStore: Store<RootState>,
    private readonly toolbarService: ToolbarService,
    private readonly permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.buildToolBarItems();
    this.dispatchActionsAndQuerySelectors();
    this.dispatchActionsAndQuerySelectorsChangeLog();
    this.getWorkAreaId();
    this.getUserPermissionForChangelog();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getUserPermissionForChangelog(): void {
    const waUserView = this.permissionService.actionAllowed(AtsActions.General_View);

    const orgUserView = this.permissionService.actionAllowed(AtsActions.General_View);

    const envUserView = this.permissionService.actionAllowed(AtsActions.General_View);

    this.canViewChangeLog = waUserView || orgUserView || envUserView;
  }

  getWorkAreaId(): void {
    this.rootStore
      .pipe(select(fromRoot.selectSelectedWorkingAreaId), takeUntil(this.ngUnsubscribe))
      .subscribe(selectedWA => {
        this.selectedWorkingArea = selectedWA;
      });
  }

  buildToolBarItems(): void {
    this.TOOLBAR_ITEMS = [];
    this.toolbarService.configureItems(this.TOOLBAR_ITEMS);
  }

  private dispatchActionsAndQuerySelectors(): void {
    this.rootStore.dispatch(fromRoot.showHideEditToggle({ isVisible: false }));
    this.currentWorkingArea$ = this.rootStore.pipe(select(fromRoot.selectSelectedWorkingArea));

    this.fleetSettings$ = this.rootStore.pipe(select(fromSettings.selectFleetSettings));
    this.fleetSettingsLoaded$ = this.rootStore.pipe(select(fromSettings.selectFleetSettingsLoaded));

    this.ipstSettings$ = this.rootStore.pipe(select(fromSettings.selectIpstFeatures));
    this.ipstSettingsLoaded$ = this.rootStore.pipe(select(fromSettings.selectIpstFeaturesLoaded));

    this.orderGatewaySettings$ = this.rootStore.pipe(
      select(fromSettings.selectOrderGatewayFeatures)
    );
    this.orderGatewaySettingsLoaded$ = this.rootStore.pipe(
      select(fromSettings.selectOrderGatewayFeaturesLoaded)
    );

    this.jobManagerSettings$ = this.rootStore.pipe(select(fromSettings.selectJobSettings));
    this.jobManagerSettingsLoaded$ = this.rootStore.pipe(
      select(fromSettings.selectJobSettingsLoaded)
    );
    this.loadTypeSettings$ = this.rootStore.pipe(select(fromSettings.selectLoadTypeSettings));
    this.trafficManagerSettings$ = this.rootStore.pipe(select(fromSettings.selectTrafficSettings));
    this.trafficManagerSettingsLoaded$ = this.rootStore.pipe(
      select(fromSettings.selectTrafficSettingsLoaded)
    );

    this.mapManagerSettings$ = this.rootStore.pipe(select(fromSettings.selectMapSettings));
    this.mapManagerSettingsLoaded$ = this.rootStore.pipe(
      select(fromSettings.selectMapSettingsLoaded)
    );

    this.evacuationDevices$ = this.rootStore.pipe(
      select(fromSettings.selectEvacuationDeviceSettings)
    );

    this.allOpcuaDevices$ = this.rootStore.pipe(select(fromOpcuaDevices.selectAllOpcuaDevices));

    this.evacuationModeTriggerActive$ = this.rootStore.pipe(
      select(fromSettings.selectEvacuationModeTriggerActive)
    );

    this.allVehicles$ = this.rootStore.pipe(
      select(fromVehicles.selectAllVehicles),
      takeUntil(this.ngUnsubscribe)
    );
    this.graphManagerSettingsLoaded$ = this.rootStore.pipe(
      select(fromSettings.selectGraphManagerFeatureSettingLoaded)
    );
  }

  dispatchActionsAndQuerySelectorsChangeLog(): void {
    this.isEditMode$ = this.rootStore.pipe(select(fromRoot.selectIsEditMode));
    this.searchTerm$ = this.toolbarService.searchTerm$.pipe(
      debounceTime(this.DEFAULT_DEBOUNCE_TIME),
      distinctUntilChanged()
    );
  }

  onselectionchange(): void {
    this.buildToolBarItems();
  }

  onSaveEndOfShiftMode(endOfShiftModeToggle: boolean): void {
    this.rootStore.dispatch(fromSettings.toggleEndOfShiftMode({ toggle: endOfShiftModeToggle }));
  }

  onSaveShutDownMode(shutdownModeToggle: ShutdownModeDto): void {
    this.rootStore.dispatch(fromSettings.toggleShutDownMode({ toggle: shutdownModeToggle }));
  }

  onSaveEvacuationMode(evacuationModeToggle: boolean): void {
    this.rootStore.dispatch(
      fromSettings.toggleEvacuationModeWorkingArea({ value: evacuationModeToggle })
    );
  }

  onSaveBeginShiftMode(beginShiftMode: boolean): void {
    this.rootStore.dispatch(fromSettings.toggleBeginShiftMode({ beginShiftMode: beginShiftMode }));
  }

  onSaveParkingAndCharging(parkingAndChargingToggle: boolean): void {
    this.rootStore.dispatch(
      fromSettings.toggleParkingAndCharging({ value: parkingAndChargingToggle })
    );
  }

  onSaveWaitOnPoi(waitOnPoiToggle: boolean): void {
    this.rootStore.dispatch(fromSettings.toggleWaitOnPoi({ value: waitOnPoiToggle }));
  }

  onSaveAmaSettings(amaEnabled: boolean): void {
    this.rootStore.dispatch(fromSettings.toggleAmaSettings({ value: amaEnabled }));
  }

  onSaveWaitingQueueSettings(waitingQueueEnabled: boolean): void {
    this.rootStore.dispatch(
      fromSettings.toggleWaitingQueueSettings({ value: waitingQueueEnabled })
    );
  }

  onRestartGraphManager(restartGraphManager: boolean): void {
    this.rootStore.dispatch(fromSettings.restartGraphManager({ restartGraphManager }));
  }

  onSaveGraphManagerSettings(enableGraphManager: boolean): void {
    this.rootStore.dispatch(fromSettings.toggleEnableGraphManager({ enableGraphManager }));
  }

  onSaveVehicleSmoothingSettings(enableVehicleSmoothing: boolean): void {
    this.rootStore.dispatch(
      fromSettings.toggleEnableVehicleSmoothing({ toggle: enableVehicleSmoothing })
    );
  }

  onSaveTrafficManagementSettings(trafficControlEnabled: boolean): void {
    this.rootStore.dispatch(fromSettings.toggleTrafficSettings({ value: trafficControlEnabled }));
  }

  onSaveBackwardDrivingPathBased(backwardDrivingPathBasedEnabled: boolean): void {
    this.rootStore.dispatch(
      fromSettings.toggleBackwardDrivingPathBased({ value: backwardDrivingPathBasedEnabled })
    );
  }

  onSaveAvoidConflictAtPois(avoidConflictAtPoisToggledOn: boolean): void {
    this.rootStore.dispatch(
      fromSettings.toggleAvoidConflictAtPois({ value: avoidConflictAtPoisToggledOn })
    );
  }

  onSaveMatrixOptimization(matrixOptimizationToggle: boolean): void {
    this.rootStore.dispatch(
      fromSettings.toggleMatrixOptimization({ toggle: matrixOptimizationToggle })
    );
  }

  onSaveBrakeTest(brakeTestToggle: boolean): void {
    this.rootStore.dispatch(fromSettings.toggleBrakeTest({ toggle: brakeTestToggle }));
  }

  onSaveIncludeTuggerTrainErrors(includeTuggerTrainErrorsToggle: boolean): void {
    this.rootStore.dispatch(
      fromSettings.toggleIncludeTuggerTrainErrors({ toggle: includeTuggerTrainErrorsToggle })
    );
  }

  onSaveNewIpstToggle(enableIpst: boolean): void {
    this.rootStore.dispatch(fromSettings.toggleNewIpstToggle({ toggle: enableIpst }));
  }

  onSaveEnableNavigationLayers(enableNavigationLayersToggle: boolean): void {
    this.rootStore.dispatch(
      fromSettings.toggleEnableNavigationLayers({ toggle: enableNavigationLayersToggle })
    );
  }

  onSaveMissionErrorHandlingDefaults(
    missionErrorHandlingDefaults: MissionErrorHandlingDefaultsDto
  ): void {
    this.rootStore.dispatch(
      fromSettings.updateMissionErrorHandlingSettings({ settings: missionErrorHandlingDefaults })
    );
  }

  onSelectOpcuaDevice(device: OpcuaDeviceResponseModel): void {
    this.rootStore.dispatch(
      fromOpcuaDevices.setSelectedDevice({
        name: device.name,
        streamingService: device.streamingServiceName,
      })
    );
  }

  onSavePktInterfaceToggle(value: boolean): void {
    this.rootStore.dispatch(
      fromSettings.togglePktInterface({
        value,
      })
    );
  }
}
