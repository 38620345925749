import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { GuidString } from 'core/models';

import { MissionListSignalr } from 'core/dtos';
import { isMissionStatusFinal } from 'core/helpers';
import * as MissionListItemActions from '../actions/mission-list-item.actions';
import * as MissionTraceActions from '../actions/mission-trace.actions';

export const featureKey = 'missionListItem';

export interface MissionListItemState extends EntityState<MissionListSignalr> {
  loading: boolean;
  loaded: boolean;
  selectedMissionTraceId: GuidString;
  errorMessage: string;
}

export const missionListItemAdapter: EntityAdapter<MissionListSignalr> =
  createEntityAdapter<MissionListSignalr>();

export const initialState: MissionListItemState = missionListItemAdapter.getInitialState({
  loading: false,
  loaded: false,
  selectedMissionTraceId: '',
  errorMessage: '',
});

export const missionListItemReducer = createReducer(
  initialState,

  on(MissionListItemActions.signalRCreateMissionList, (state, { message: missions }) =>
    missionListItemAdapter.setAll(missions, {
      ...state,
      loaded: true,
      loading: false,
    })
  ),

  on(MissionTraceActions.loadMissionTracesSuccess, (state, { missionTrace: traces }) => {
    return missionListItemAdapter.upsertMany(traces, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),

  on(MissionListItemActions.signalRUpdateMissionList, (state, { message: missions }) =>
    missionListItemAdapter.upsertMany(missions, {
      ...state,
      loaded: true,
      loading: false,
    })
  ),

  on(MissionListItemActions.signalRRemoveCompletedMissions, state => {
    const tenSecondsInMs = 10000;
    const removeItems = getAllMissionListItems(state).filter(
      x =>
        isMissionStatusFinal(x.status) &&
        new Date(new Date().getTime() - tenSecondsInMs) < new Date(x.updatedDateTime)
    );
    return missionListItemAdapter.removeMany(
      removeItems.map(i => i.id.toString()),
      {
        ...state,
        loaded: true,
        loading: false,
      }
    );
  }),

  on(MissionListItemActions.loadActiveTourListItemsSuccess, (state, { items }) =>
    missionListItemAdapter.upsertMany(items, {
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(MissionListItemActions.selectMissionListItem, (state, { missionTraceId }) => ({
    ...state,
    selectedMissionTraceId: missionTraceId,
  }))
);

export function reducer(
  state: MissionListItemState | undefined,
  action: Action
): MissionListItemState {
  return missionListItemReducer(state, action);
}

export const { selectEntities, selectAll } = missionListItemAdapter.getSelectors();

export const getSelectedMissionTraceId = (state: MissionListItemState): GuidString =>
  (state || initialState).selectedMissionTraceId;
export const getEntities = selectEntities;
export const getAllMissionListItems = selectAll;
