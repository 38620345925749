import {
  PackedConflictZone,
  PackedPathEdge,
  PathEdgeDto,
  VehicleConflictAreaDimensions,
  VehicleIntersectionZonePathUpdate,
  VehicleLocationSignalrDto,
  VehicleLocationUpdate,
} from 'core/dtos';
import { GuidString } from 'core/models';
import { toPositionWithOrientation, toPositionWithTheta } from './unpack-functions.helper';

export class MapPollingUnpackHelper {
  private static unpackPathEdge(e: PackedPathEdge): PathEdgeDto {
    return {
      lineSegment: { p0: { x: e[0], y: e[1] }, p1: { x: e[2], y: e[3] } },
      width: e[4],
    };
  }

  static unpackVehicle(data: VehicleLocationSignalrDto): VehicleLocationUpdate {
    const [mm, err, pau, chg, busy, ret, off, conn, load] = data.flags;

    return {
      id: data.id,
      name: data.name,
      maintenanceModeEnabled: mm,
      hasError: err,
      isPaused: pau,
      isCharging: chg,
      isBusy: busy,
      isRetired: ret,
      isSwitchedOff: off,
      isConnected: conn,
      isLoaded: load,
      mode: data.mode,
      pose2D: toPositionWithOrientation(data.pose2D),
      path: data.path.map(toPositionWithTheta),
      trailers:
        data.trailers
          ?.map(toPositionWithOrientation)
          .map(it => ({ trailerId: '', trailerPosition: it })) ?? null,
    };
  }

  static unpackConflictArea(
    waId: GuidString,
    vehicleId: GuidString,
    data: PackedConflictZone
  ): VehicleConflictAreaDimensions {
    return {
      workAreaId: waId,
      vehicleId,
      lookAheadArea: data[0]?.map(MapPollingUnpackHelper.unpackPathEdge) ?? null,
      stoppingArea: data[1]?.map(MapPollingUnpackHelper.unpackPathEdge) ?? null,
      deadlockArea: data[2]?.map(MapPollingUnpackHelper.unpackPathEdge) ?? null,
    };
  }

  static unpackZonePath(
    mapId: GuidString,
    vehicleId: GuidString,
    data: [number, number, number, number, number][] | null
  ): VehicleIntersectionZonePathUpdate {
    const unpackedPath = data?.map(MapPollingUnpackHelper.unpackPathEdge) ?? [];
    return {
      vehicleId,
      mapId,
      intersectionZonePath: unpackedPath,
    };
  }
}
