/* eslint-disable max-lines */
enum AtsActionsEnum {
  OrganizationCreate = 'Organization_Create',
  OrganizationEdit = 'Organization_Edit',
  OrganizationDelete = 'Organization_Delete',

  WorkingAreaCreate = 'WorkingArea_Create',
  WorkingAreaEdit = 'WorkingArea_Edit',
  WorkingAreaDelete = 'WorkingArea_Delete',

  EnvironmentSettingsEdit = 'Environment_Settings_Edit',

  MapCreate = 'Map_Create',
  MapEdit = 'Map_Edit',
  MapDelete = 'Map_Delete',

  PillarGridCreate = 'PillarGrid_Create',
  PillarGridEdit = 'PillarGrid_Edit',
  PillarGridDelete = 'PillarGrid_Delete',

  ZoneSetsManage = 'ZoneSets_Manage',
  ZoneSetsChangeStatus = 'ZoneSets_Change_Status',
  ActivateInteractionZone = 'Activate_Interaction_Zone',

  LifNodeManage = 'Lif_Node_Manage',

  PoiCreate = 'Poi_Create',
  PoiEdit = 'Poi_Edit',
  PoiDelete = 'Poi_Delete',

  MappingCreate = 'Mapping_Create',
  MappingEdit = 'Mapping_Edit',
  MappingDelete = 'Mapping_Delete',

  MissionTraceComment = 'MissionTrace_Comment',
  MissionTracePauseCancelRetryAbort = 'MissionTrace_PauseCancelRetryAbort',

  ProcessChainCreate = 'ProcessChain_Create',
  ProcessChainEdit = 'ProcessChain_Edit',
  ProcessChainDelete = 'ProcessChain_Delete',
  ProcessChainTrigger = 'ProcessChain_Trigger',
  ProcessChainActivate = 'ProcessChain_Activate',

  ProcessChainRepeatLastStep = 'ProcessChain_RepeatLastStep',

  VehicleCreate = 'Vehicle_Create',
  VehicleEdit = 'Vehicle_Edit',
  VehicleDelete = 'Vehicle_Delete',
  VehicleChangeWorkingArea = 'Vehicle_ChangeWorkingArea',
  VehicleSendStepOrMission = 'Vehicle_SendStepOrMission',
  VehicleSendBreakTest = 'Vehicle_SendBreakTest',
  VehicleUpdateMap = 'Vehicle_UpdateMap',
  VehicleUpdateZone = 'Vehicle_UpdateZone',
  VehicleToggleMaintenanceMode = 'Vehicle_ToggleMaintenanceMode',
  VehicleUpdateErrorForwarding = 'Vehicle_UpdateErrorForwarding',
  VehicleLocalize = 'Vehicle_Localize',
  VehicleOnBoarding = 'Vehicle_Onboarding',
  VehicleAccess = 'Vehicle_DirectAccess',
  VehicleWakeOtherVehicles = 'Vehicle_WakeOtherVehicles',
  VehicleMassCommandUpdateZones = 'Vehicle_MassCommandUpdateZones',
  VehicleZoneAccess = 'Vehicle_ZoneAccess',

  VehicleInitiateMapUpload = 'Vehicle_InitiateMapUpload',
  VehicleMapDataDelete = 'VehicleMapData_Delete',

  FleetCreate = 'Fleet_Create',
  FleetEdit = 'Fleet_Edit',
  FleetDelete = 'Fleet_Delete',

  SoftwareUpdateView = 'Software_Update_View',
  SoftwareUpdateCreate = 'Software_Update_Create',
  SoftwareUpdateDelete = 'Software_Update_Delete',
  SoftwareUpdateDispatch = 'Software_Update_Dispatch',

  ReportsFilter = 'Reports_ApplyFilters',
  ReportsDownloadAndEmail = 'Reports_DownloadAndEmail',

  ToggleSendingErrors = 'ToggleSendingErrors',
  IpstSettingsEdit = 'IpstSettingsEdit',

  ToggleParkyChargy = 'ToggleParkyChargy',
  ToggleWaitOnPoi = 'ToggleWaitOnPoi',
  ToggleTrafficManagement = 'ToggleTrafficManagement',
  ToggleMissionAssignment = 'ToggleMissionAssignment',
  ToggleBrakeTest = 'ToggleBrakeTest',
  ToggleAMAFunctionality = 'ToggleAMAFunctionality',
  ToggleEndOfShift = 'ToggleEndOfShift',
  ToggleWaitingQueueMode = 'ToggleWaitingQueueMode',
  ToggleEmergencyStrategyWorkingArea = 'ToggleEmergencyStrategyWorkingArea',
  ToggleEmergencyStrategyWelcomePage = 'ToggleEmergencyStrategyWelcomePage',
  EvacuationDeviceUpdate = 'Evacuation_Device_Update',
  ToggleErrorMessagesTuggerTrain = 'ToggleErrorMessagesTuggerTrain',
  ToggleMassShutdown = 'ToggleMassShutdown',
  ToggleNavigationLayers = 'ToggleNavigationLayers',
  ToggleVehicleSmoothing = 'ToggleVehicleSmoothing',
  TogglePktInterface = 'TogglePktInterface',
  ToggleCollectivePause = 'ToggleCollectivePause',

  MissionErrorHandlingDefaultsEdit = 'MissionErrorHandlingDefaults_Edit',
  TogglePathStrategy = 'TogglePathStrategy',
  PeripheralDeviceConnectionCreate = 'PeripheralDeviceConnection_Create',
  PeripheralDeviceConnectionEdit = 'PeripheralDeviceConnection_Edit',
  PeripheralDeviceConnectionDelete = 'PeripheralDeviceConnection_Delete',

  EditFunctionalParameters = 'EditFunctionalParameters',

  InfoBarMessageCrud = 'InfoBar_Crud',

  ViewerSupportCreate = 'Viewer_Support_Create',
  ViewerOperatorSupervisorCreate = 'Viewer_Operator_Supervisor_Create',
  EnvironmentAdminCreate = 'EnvironmentAdmin_Create',
  OrganizationAdminCreate = 'OrganizationAdmin_Create',
  WorkingAreaAdminCreate = 'WorkingAreaAdmin_Create',
  EnvironmentUsersEdit = 'Environment_Users_Edit',
  OrganizationUsersEdit = 'Organization_Users_Edit',
  WorkingAreaUsersEdit = 'WorkingArea_Users_Edit',
  EnvironmentUsersDelete = 'Environment_Users_Delete',
  RolesEdit = 'Roles_Edit',

  GeneralView = 'General_View',
  FailureSettingDescriptionCreate = 'Failure_Categories_Description_Create',
  Failure_Categories_Edit = 'Failure_Categories_Edit',
  Comment_Overview_Edit = 'Comment_Overview_Edit',
  Comment_Settings_Edit = 'Comment_Settings_Edit',
  EditPktParameters = 'EditPktParameters',
  ResolveAllIpstMessages = 'ResolveAllIpstMessages',
}

export const AtsActions = {
  [AtsActionsEnum.OrganizationCreate]: AtsActionsEnum.OrganizationCreate,
  [AtsActionsEnum.OrganizationEdit]: AtsActionsEnum.OrganizationEdit,
  [AtsActionsEnum.OrganizationDelete]: AtsActionsEnum.OrganizationDelete,

  [AtsActionsEnum.WorkingAreaCreate]: AtsActionsEnum.WorkingAreaCreate,
  [AtsActionsEnum.WorkingAreaEdit]: AtsActionsEnum.WorkingAreaEdit,
  [AtsActionsEnum.WorkingAreaDelete]: AtsActionsEnum.WorkingAreaDelete,

  [AtsActionsEnum.EnvironmentSettingsEdit]: AtsActionsEnum.EnvironmentSettingsEdit,

  [AtsActionsEnum.InfoBarMessageCrud]: AtsActionsEnum.InfoBarMessageCrud,

  [AtsActionsEnum.MapCreate]: AtsActionsEnum.MapCreate,
  [AtsActionsEnum.MapEdit]: AtsActionsEnum.MapEdit,
  [AtsActionsEnum.MapDelete]: AtsActionsEnum.MapDelete,

  [AtsActionsEnum.LifNodeManage]: AtsActionsEnum.LifNodeManage,

  [AtsActionsEnum.PillarGridCreate]: AtsActionsEnum.PillarGridCreate,
  [AtsActionsEnum.PillarGridEdit]: AtsActionsEnum.PillarGridEdit,
  [AtsActionsEnum.PillarGridDelete]: AtsActionsEnum.PillarGridDelete,

  [AtsActionsEnum.ZoneSetsManage]: AtsActionsEnum.ZoneSetsManage,
  [AtsActionsEnum.ZoneSetsChangeStatus]: AtsActionsEnum.ZoneSetsChangeStatus,
  [AtsActionsEnum.ActivateInteractionZone]: AtsActionsEnum.ActivateInteractionZone,

  [AtsActionsEnum.PoiCreate]: AtsActionsEnum.PoiCreate,
  [AtsActionsEnum.PoiEdit]: AtsActionsEnum.PoiEdit,
  [AtsActionsEnum.PoiDelete]: AtsActionsEnum.PoiDelete,

  [AtsActionsEnum.MappingCreate]: AtsActionsEnum.MappingCreate,
  [AtsActionsEnum.MappingEdit]: AtsActionsEnum.MappingEdit,
  [AtsActionsEnum.MappingDelete]: AtsActionsEnum.MappingDelete,

  [AtsActionsEnum.MissionTraceComment]: AtsActionsEnum.MissionTraceComment,
  [AtsActionsEnum.MissionTracePauseCancelRetryAbort]:
    AtsActionsEnum.MissionTracePauseCancelRetryAbort,

  [AtsActionsEnum.ProcessChainCreate]: AtsActionsEnum.ProcessChainCreate,
  [AtsActionsEnum.ProcessChainEdit]: AtsActionsEnum.ProcessChainEdit,
  [AtsActionsEnum.ProcessChainDelete]: AtsActionsEnum.ProcessChainDelete,
  [AtsActionsEnum.ProcessChainTrigger]: AtsActionsEnum.ProcessChainTrigger,
  [AtsActionsEnum.ProcessChainActivate]: AtsActionsEnum.ProcessChainActivate,

  [AtsActionsEnum.ProcessChainRepeatLastStep]: AtsActionsEnum.ProcessChainRepeatLastStep,

  [AtsActionsEnum.VehicleCreate]: AtsActionsEnum.VehicleCreate,
  [AtsActionsEnum.VehicleEdit]: AtsActionsEnum.VehicleEdit,
  [AtsActionsEnum.VehicleDelete]: AtsActionsEnum.VehicleDelete,

  [AtsActionsEnum.VehicleChangeWorkingArea]: AtsActionsEnum.VehicleChangeWorkingArea,
  [AtsActionsEnum.VehicleSendStepOrMission]: AtsActionsEnum.VehicleSendStepOrMission,
  [AtsActionsEnum.VehicleSendBreakTest]: AtsActionsEnum.VehicleSendBreakTest,
  [AtsActionsEnum.VehicleUpdateMap]: AtsActionsEnum.VehicleUpdateMap,
  [AtsActionsEnum.VehicleUpdateZone]: AtsActionsEnum.VehicleUpdateZone,
  [AtsActionsEnum.VehicleToggleMaintenanceMode]: AtsActionsEnum.VehicleToggleMaintenanceMode,
  [AtsActionsEnum.VehicleUpdateErrorForwarding]: AtsActionsEnum.VehicleUpdateErrorForwarding,
  [AtsActionsEnum.VehicleLocalize]: AtsActionsEnum.VehicleLocalize,
  [AtsActionsEnum.VehicleOnBoarding]: AtsActionsEnum.VehicleOnBoarding,
  [AtsActionsEnum.VehicleAccess]: AtsActionsEnum.VehicleAccess,
  [AtsActionsEnum.VehicleWakeOtherVehicles]: AtsActionsEnum.VehicleWakeOtherVehicles,

  [AtsActionsEnum.VehicleMassCommandUpdateZones]: AtsActionsEnum.VehicleMassCommandUpdateZones,
  [AtsActionsEnum.VehicleInitiateMapUpload]: AtsActionsEnum.VehicleInitiateMapUpload,
  [AtsActionsEnum.VehicleMapDataDelete]: AtsActionsEnum.VehicleMapDataDelete,
  [AtsActionsEnum.VehicleZoneAccess]: AtsActionsEnum.VehicleZoneAccess,

  [AtsActionsEnum.FleetCreate]: AtsActionsEnum.FleetCreate,
  [AtsActionsEnum.FleetEdit]: AtsActionsEnum.FleetEdit,
  [AtsActionsEnum.FleetDelete]: AtsActionsEnum.FleetDelete,

  [AtsActionsEnum.SoftwareUpdateView]: AtsActionsEnum.SoftwareUpdateView,
  [AtsActionsEnum.SoftwareUpdateCreate]: AtsActionsEnum.SoftwareUpdateCreate,
  [AtsActionsEnum.SoftwareUpdateDelete]: AtsActionsEnum.SoftwareUpdateDelete,
  [AtsActionsEnum.SoftwareUpdateDispatch]: AtsActionsEnum.SoftwareUpdateDispatch,

  [AtsActionsEnum.ReportsFilter]: AtsActionsEnum.ReportsFilter,
  [AtsActionsEnum.ReportsDownloadAndEmail]: AtsActionsEnum.ReportsDownloadAndEmail,

  [AtsActionsEnum.ToggleSendingErrors]: AtsActionsEnum.ToggleSendingErrors,
  [AtsActionsEnum.IpstSettingsEdit]: AtsActionsEnum.IpstSettingsEdit,

  [AtsActionsEnum.ToggleParkyChargy]: AtsActionsEnum.ToggleParkyChargy,
  [AtsActionsEnum.ToggleWaitOnPoi]: AtsActionsEnum.ToggleWaitOnPoi,
  [AtsActionsEnum.ToggleTrafficManagement]: AtsActionsEnum.ToggleTrafficManagement,
  [AtsActionsEnum.ToggleMissionAssignment]: AtsActionsEnum.ToggleMissionAssignment,
  [AtsActionsEnum.ToggleBrakeTest]: AtsActionsEnum.ToggleBrakeTest,
  [AtsActionsEnum.ToggleAMAFunctionality]: AtsActionsEnum.ToggleAMAFunctionality,
  [AtsActionsEnum.ToggleEndOfShift]: AtsActionsEnum.ToggleEndOfShift,

  [AtsActionsEnum.ToggleWaitingQueueMode]: AtsActionsEnum.ToggleWaitingQueueMode,
  [AtsActionsEnum.ToggleEmergencyStrategyWorkingArea]:
    AtsActionsEnum.ToggleEmergencyStrategyWorkingArea,
  [AtsActionsEnum.ToggleEmergencyStrategyWelcomePage]:
    AtsActionsEnum.ToggleEmergencyStrategyWelcomePage,
  [AtsActionsEnum.EvacuationDeviceUpdate]: AtsActionsEnum.EvacuationDeviceUpdate,
  [AtsActionsEnum.ToggleErrorMessagesTuggerTrain]: AtsActionsEnum.ToggleErrorMessagesTuggerTrain,
  [AtsActionsEnum.ToggleMassShutdown]: AtsActionsEnum.ToggleMassShutdown,
  [AtsActionsEnum.ToggleCollectivePause]: AtsActionsEnum.ToggleCollectivePause,
  [AtsActionsEnum.MissionErrorHandlingDefaultsEdit]:
    AtsActionsEnum.MissionErrorHandlingDefaultsEdit,
  [AtsActionsEnum.TogglePathStrategy]: AtsActionsEnum.TogglePathStrategy,
  [AtsActionsEnum.ToggleNavigationLayers]: AtsActionsEnum.ToggleNavigationLayers,
  [AtsActionsEnum.ToggleVehicleSmoothing]: AtsActionsEnum.ToggleVehicleSmoothing,
  [AtsActionsEnum.TogglePktInterface]: AtsActionsEnum.TogglePktInterface,

  [AtsActionsEnum.PeripheralDeviceConnectionCreate]:
    AtsActionsEnum.PeripheralDeviceConnectionCreate,
  [AtsActionsEnum.PeripheralDeviceConnectionEdit]: AtsActionsEnum.PeripheralDeviceConnectionEdit,
  [AtsActionsEnum.PeripheralDeviceConnectionDelete]:
    AtsActionsEnum.PeripheralDeviceConnectionDelete,

  [AtsActionsEnum.EditFunctionalParameters]: AtsActionsEnum.EditFunctionalParameters,

  [AtsActionsEnum.ViewerSupportCreate]: AtsActionsEnum.ViewerSupportCreate,
  [AtsActionsEnum.ViewerOperatorSupervisorCreate]: AtsActionsEnum.ViewerOperatorSupervisorCreate,
  [AtsActionsEnum.EnvironmentAdminCreate]: AtsActionsEnum.EnvironmentAdminCreate,
  [AtsActionsEnum.OrganizationAdminCreate]: AtsActionsEnum.OrganizationAdminCreate,
  [AtsActionsEnum.WorkingAreaAdminCreate]: AtsActionsEnum.WorkingAreaAdminCreate,
  [AtsActionsEnum.EnvironmentUsersEdit]: AtsActionsEnum.EnvironmentUsersEdit,
  [AtsActionsEnum.OrganizationUsersEdit]: AtsActionsEnum.OrganizationUsersEdit,
  [AtsActionsEnum.WorkingAreaUsersEdit]: AtsActionsEnum.WorkingAreaUsersEdit,
  [AtsActionsEnum.EnvironmentUsersDelete]: AtsActionsEnum.EnvironmentUsersDelete,
  [AtsActionsEnum.RolesEdit]: AtsActionsEnum.RolesEdit,

  [AtsActionsEnum.GeneralView]: AtsActionsEnum.GeneralView,
  [AtsActionsEnum.Failure_Categories_Edit]: AtsActionsEnum.Failure_Categories_Edit,
  [AtsActionsEnum.Comment_Overview_Edit]: AtsActionsEnum.Comment_Overview_Edit,
  [AtsActionsEnum.FailureSettingDescriptionCreate]: AtsActionsEnum.FailureSettingDescriptionCreate,
  [AtsActionsEnum.Comment_Settings_Edit]: AtsActionsEnum.Comment_Settings_Edit,
  [AtsActionsEnum.EditPktParameters]: AtsActionsEnum.EditPktParameters,
  [AtsActionsEnum.ResolveAllIpstMessages]: AtsActionsEnum.ResolveAllIpstMessages,
};

export enum AtsActionsGroupingEnum {
  Map = 'Map',
  NavigationLayers = 'NavigationLayers',
  POIs = 'POIs',
  MappingTable = 'MappingTable',
  Reports = 'Reports',
  ActiveMissions = 'ActiveMissions',
  HistoryMissions = 'HistoryMissions',
  ProcessConfigurator = 'ProcessConfigurator',
  Vehicle = 'Vehicle',
  ErrorForwarding = 'ErrorForwarding',
  PlantTab = 'PlantTab',
  UserPermissions = 'UserPermissions',
  PeripheralDeviceConnections = 'PeripheralDeviceConnections',
  SystemPreferences = 'SystemPreferences',
  Misc = 'Misc',
  Analysis = 'Analysis',
  FailureCommenting = 'FailureCommenting',
}

export const AtsActionsGrouping = {
  [AtsActionsEnum.OrganizationCreate]: AtsActionsGroupingEnum.PlantTab,
  [AtsActionsEnum.OrganizationEdit]: AtsActionsGroupingEnum.PlantTab,
  [AtsActionsEnum.OrganizationDelete]: AtsActionsGroupingEnum.PlantTab,

  [AtsActionsEnum.WorkingAreaCreate]: AtsActionsGroupingEnum.PlantTab,
  [AtsActionsEnum.WorkingAreaEdit]: AtsActionsGroupingEnum.PlantTab,
  [AtsActionsEnum.WorkingAreaDelete]: AtsActionsGroupingEnum.PlantTab,

  [AtsActionsEnum.EnvironmentSettingsEdit]: AtsActionsGroupingEnum.PlantTab,

  [AtsActionsEnum.InfoBarMessageCrud]: AtsActionsGroupingEnum.PlantTab,

  [AtsActionsEnum.MapCreate]: AtsActionsGroupingEnum.Map,
  [AtsActionsEnum.MapEdit]: AtsActionsGroupingEnum.Map,
  [AtsActionsEnum.MapDelete]: AtsActionsGroupingEnum.Map,

  [AtsActionsEnum.LifNodeManage]: AtsActionsGroupingEnum.Map,

  [AtsActionsEnum.PillarGridCreate]: AtsActionsGroupingEnum.Map,
  [AtsActionsEnum.PillarGridEdit]: AtsActionsGroupingEnum.Map,
  [AtsActionsEnum.PillarGridDelete]: AtsActionsGroupingEnum.Map,

  [AtsActionsEnum.ZoneSetsManage]: AtsActionsGroupingEnum.Map,
  [AtsActionsEnum.ZoneSetsChangeStatus]: AtsActionsGroupingEnum.Map,
  [AtsActionsEnum.ActivateInteractionZone]: AtsActionsGroupingEnum.Map,
  [AtsActionsEnum.VehicleMapDataDelete]: AtsActionsGroupingEnum.Map,

  [AtsActionsEnum.PoiCreate]: AtsActionsGroupingEnum.POIs,
  [AtsActionsEnum.PoiEdit]: AtsActionsGroupingEnum.POIs,
  [AtsActionsEnum.PoiDelete]: AtsActionsGroupingEnum.POIs,

  [AtsActionsEnum.MappingCreate]: AtsActionsGroupingEnum.MappingTable,
  [AtsActionsEnum.MappingEdit]: AtsActionsGroupingEnum.MappingTable,
  [AtsActionsEnum.MappingDelete]: AtsActionsGroupingEnum.MappingTable,

  [AtsActionsEnum.MissionTraceComment]: AtsActionsGroupingEnum.HistoryMissions,
  [AtsActionsEnum.MissionTracePauseCancelRetryAbort]: AtsActionsGroupingEnum.ActiveMissions,

  [AtsActionsEnum.ProcessChainCreate]: AtsActionsGroupingEnum.ProcessConfigurator,
  [AtsActionsEnum.ProcessChainEdit]: AtsActionsGroupingEnum.ProcessConfigurator,
  [AtsActionsEnum.ProcessChainDelete]: AtsActionsGroupingEnum.ProcessConfigurator,
  [AtsActionsEnum.ProcessChainTrigger]: AtsActionsGroupingEnum.ProcessConfigurator,
  [AtsActionsEnum.ProcessChainActivate]: AtsActionsGroupingEnum.ProcessConfigurator,

  [AtsActionsEnum.ProcessChainRepeatLastStep]: AtsActionsGroupingEnum.ActiveMissions,

  [AtsActionsEnum.VehicleCreate]: AtsActionsGroupingEnum.Vehicle,
  [AtsActionsEnum.VehicleEdit]: AtsActionsGroupingEnum.Vehicle,
  [AtsActionsEnum.VehicleDelete]: AtsActionsGroupingEnum.Vehicle,

  [AtsActionsEnum.VehicleChangeWorkingArea]: AtsActionsGroupingEnum.Vehicle,
  [AtsActionsEnum.VehicleSendStepOrMission]: AtsActionsGroupingEnum.Vehicle,
  [AtsActionsEnum.VehicleSendBreakTest]: AtsActionsGroupingEnum.Vehicle,
  [AtsActionsEnum.VehicleUpdateMap]: AtsActionsGroupingEnum.Vehicle,
  [AtsActionsEnum.VehicleUpdateZone]: AtsActionsGroupingEnum.Vehicle,
  [AtsActionsEnum.VehicleToggleMaintenanceMode]: AtsActionsGroupingEnum.Vehicle,
  [AtsActionsEnum.VehicleUpdateErrorForwarding]: AtsActionsGroupingEnum.Vehicle,
  [AtsActionsEnum.VehicleLocalize]: AtsActionsGroupingEnum.Vehicle,
  [AtsActionsEnum.VehicleOnBoarding]: AtsActionsGroupingEnum.Vehicle,
  [AtsActionsEnum.VehicleAccess]: AtsActionsGroupingEnum.Vehicle,
  [AtsActionsEnum.VehicleWakeOtherVehicles]: AtsActionsGroupingEnum.Vehicle,

  [AtsActionsEnum.VehicleMassCommandUpdateZones]: AtsActionsGroupingEnum.Vehicle,
  [AtsActionsEnum.VehicleInitiateMapUpload]: AtsActionsGroupingEnum.Vehicle,
  [AtsActionsEnum.VehicleZoneAccess]: AtsActionsGroupingEnum.Vehicle,

  [AtsActionsEnum.FleetCreate]: AtsActionsGroupingEnum.Vehicle,
  [AtsActionsEnum.FleetEdit]: AtsActionsGroupingEnum.Vehicle,
  [AtsActionsEnum.FleetDelete]: AtsActionsGroupingEnum.Vehicle,

  [AtsActionsEnum.SoftwareUpdateView]: AtsActionsGroupingEnum.Vehicle,
  [AtsActionsEnum.SoftwareUpdateCreate]: AtsActionsGroupingEnum.Vehicle,
  [AtsActionsEnum.SoftwareUpdateDelete]: AtsActionsGroupingEnum.Vehicle,
  [AtsActionsEnum.SoftwareUpdateDispatch]: AtsActionsGroupingEnum.Vehicle,

  [AtsActionsEnum.ReportsFilter]: AtsActionsGroupingEnum.Reports,
  [AtsActionsEnum.ReportsDownloadAndEmail]: AtsActionsGroupingEnum.Reports,

  [AtsActionsEnum.ToggleSendingErrors]: AtsActionsGroupingEnum.ErrorForwarding,
  [AtsActionsEnum.IpstSettingsEdit]: AtsActionsGroupingEnum.ErrorForwarding,

  [AtsActionsEnum.ToggleParkyChargy]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.ToggleWaitOnPoi]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.ToggleTrafficManagement]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.ToggleMissionAssignment]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.ToggleBrakeTest]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.ToggleAMAFunctionality]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.ToggleEndOfShift]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.ToggleWaitingQueueMode]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.ToggleEmergencyStrategyWorkingArea]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.ToggleEmergencyStrategyWelcomePage]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.EvacuationDeviceUpdate]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.ToggleErrorMessagesTuggerTrain]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.ToggleMassShutdown]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.ToggleCollectivePause]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.MissionErrorHandlingDefaultsEdit]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.TogglePathStrategy]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.ToggleNavigationLayers]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.ToggleVehicleSmoothing]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.TogglePktInterface]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.EditFunctionalParameters]: AtsActionsGroupingEnum.SystemPreferences,
  [AtsActionsEnum.EditPktParameters]: AtsActionsGroupingEnum.SystemPreferences,

  [AtsActionsEnum.PeripheralDeviceConnectionCreate]:
    AtsActionsGroupingEnum.PeripheralDeviceConnections,
  [AtsActionsEnum.PeripheralDeviceConnectionEdit]:
    AtsActionsGroupingEnum.PeripheralDeviceConnections,
  [AtsActionsEnum.PeripheralDeviceConnectionDelete]:
    AtsActionsGroupingEnum.PeripheralDeviceConnections,

  [AtsActionsEnum.ViewerSupportCreate]: AtsActionsGroupingEnum.UserPermissions,
  [AtsActionsEnum.ViewerOperatorSupervisorCreate]: AtsActionsGroupingEnum.UserPermissions,
  [AtsActionsEnum.EnvironmentAdminCreate]: AtsActionsGroupingEnum.UserPermissions,
  [AtsActionsEnum.OrganizationAdminCreate]: AtsActionsGroupingEnum.UserPermissions,
  [AtsActionsEnum.WorkingAreaAdminCreate]: AtsActionsGroupingEnum.UserPermissions,
  [AtsActionsEnum.EnvironmentUsersEdit]: AtsActionsGroupingEnum.UserPermissions,
  [AtsActionsEnum.OrganizationUsersEdit]: AtsActionsGroupingEnum.UserPermissions,
  [AtsActionsEnum.WorkingAreaUsersEdit]: AtsActionsGroupingEnum.UserPermissions,
  [AtsActionsEnum.EnvironmentUsersDelete]: AtsActionsGroupingEnum.UserPermissions,
  [AtsActionsEnum.RolesEdit]: AtsActionsGroupingEnum.UserPermissions,
  [AtsActionsEnum.GeneralView]: AtsActionsGroupingEnum.UserPermissions,

  [AtsActionsEnum.Failure_Categories_Edit]: AtsActionsGroupingEnum.FailureCommenting,
  [AtsActionsEnum.Comment_Overview_Edit]: AtsActionsGroupingEnum.FailureCommenting,
  [AtsActionsEnum.FailureSettingDescriptionCreate]: AtsActionsGroupingEnum.FailureCommenting,
  [AtsActionsEnum.Comment_Settings_Edit]: AtsActionsGroupingEnum.FailureCommenting,
  [AtsActionsEnum.ResolveAllIpstMessages]: AtsActionsGroupingEnum.ErrorForwarding,
};
