<form [formGroup]="mainForm">
  <div style="flex: 0">
    <h3>{{ 'settings.ipst.general' | translate }}</h3>
    <app-ipst-settings-view *ngIf="(isEditMode$ | async) === false" [ipstSettings]="ipstSettings">
    </app-ipst-settings-view>
    <app-ipst-settings-edit
      *ngIf="isEditMode$ | async"
      [formControl]="$any(mainForm.get('header'))"
      ngDefaultControl>
    </app-ipst-settings-edit>
  </div>
  <div style="flex: 1">
    <h3>{{ 'settings.ipst.workingAreas' | translate }}</h3>
    <app-ipst-groups-view
      *ngIf="(isEditMode$ | async) === false"
      [viewModel]="ipstSettings.workingAreaSettings"
      [lastIpstMessageResolved]="lastIpstMessageResolved"
      (resolveAllIpstMessages)="resolveAllIpstMessages($event)">
    </app-ipst-groups-view>

    <app-ipst-groups-edit
      *ngIf="isEditMode$ | async"
      [formControl]="$any(mainForm.get('workingAreaSettings'))"
      ngDefaultControl>
    </app-ipst-groups-edit>
  </div>
</form>
