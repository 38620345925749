/* eslint-disable max-lines */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { IpstSettingsService } from 'core/api-services';
import { EMPTY_GUID } from 'core/constants';
import {
  FeatureToggle,
  IpstServiceFeatures,
  IpstSettingsDto,
  OrganizationDto,
  WorkingAreaDto,
} from 'core/dtos';
import { filterUndefined } from 'core/helpers';
import objectHelper from 'core/helpers/object.helper';
import {
  AtsActions,
  DateString,
  GuidString,
  IpstSettings,
  IpstWorkingAreaSetting,
  ToolBarItem,
} from 'core/models';
import { EditBarService, PermissionService, ToastService, ToolbarService } from 'core/services';
import { isEqual } from 'lodash';
import { Observable, Subject, distinctUntilChanged, of, switchMap, take, takeUntil } from 'rxjs';
import * as fromSettings from 'store-modules/settings-store';
import * as fromRoot from 'store/index';

@Component({
  selector: 'app-ipst-alertnow-settings-container',
  templateUrl: './ipst-alertnow-settings-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstAlertNowSettingsContainerComponent implements OnInit, OnDestroy {
  ipstSettings$: Observable<IpstServiceFeatures> = of();
  ipstSettingsLoaded$: Observable<boolean> = of(false);
  ipstLoadLastMessageResolve$: Observable<DateString> = of();
  selectedWorkingAreaId$: Observable<GuidString> = of('');
  isEditMode$: Observable<boolean> = of(false);
  ngUnsubscribe = new Subject<void>();
  searchTerm$: Observable<string> = of('');
  activeTabId: string | undefined;
  openTab = 'ipstServiceTab';
  TOOLBAR_ITEMS: ToolBarItem[] = [];
  selectedOrganization?: OrganizationDto;
  selectedWorkingArea?: WorkingAreaDto;
  ipstWorkingAreaSettingV2Dto: IpstWorkingAreaSetting = {
    akz: '',
    enabled: false,
    errorLanguage: 1,
    workingAreaId: EMPTY_GUID,
    defaultRecipientKey: '',
    recipientKeys: [''],
    workingArea: '',
  };
  originalIpstWorkingAreaSettingV2Dto: IpstWorkingAreaSetting = {
    akz: '',
    enabled: false,
    errorLanguage: 1,
    workingAreaId: EMPTY_GUID,
    defaultRecipientKey: '',
    recipientKeys: [''],
    workingArea: '',
  };
  generalInformation: IpstSettings = {
    url: '',
    user: '',
    organizationId: EMPTY_GUID,
    password: '',
    workingAreaSettings: [],
  };

  originalGeneralInformation: IpstSettings = {
    url: '',
    user: '',
    organizationId: EMPTY_GUID,
    password: '',
    workingAreaSettings: [],
  };

  mainForm: UntypedFormGroup;
  hasChanges = false;
  originalTuggerTrainErrorsToggle: FeatureToggle = { isToggledOn: false, dateUpdated: null };
  editedTuggerTrainErrorsToggle: FeatureToggle = { isToggledOn: false, dateUpdated: null };
  lastIpstMessageResolved = false;

  constructor(
    private readonly toolbarService: ToolbarService,
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly permissionService: PermissionService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly ipstService: IpstSettingsService,
    private readonly editBarService: EditBarService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly toastService: ToastService
  ) {
    this.mainForm = this.createMainForm();
  }

  createMainForm(): UntypedFormGroup {
    return this.formBuilder.group({
      generalInformation: this.formBuilder.control({}),
      ipstSettings: this.formBuilder.control({}),
    });
  }

  ngOnInit(): void {
    this.buildToolBarItems();
    this.dispatchActionsAndQuerySelectors();
    this.initializeSubscriptions();
    this.onEditFormChanges();
    this.subscribeToIpstSettingsTuggerTrainErrors();
  }

  dispatchActionsAndQuerySelectors(): void {
    this.editBarService.setCanDelete(false);
    this.editBarService.onCancel$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.onCancel.bind(this));
    this.editBarService.onSave = this.onSave.bind(this);
    this.searchTerm$ = this.toolbarService.searchTerm$.pipe(distinctUntilChanged());
    this.ipstSettings$ = this.rootStore.pipe(select(fromSettings.selectIpstFeatures));
    this.ipstSettingsLoaded$ = this.rootStore.pipe(select(fromSettings.selectIpstFeaturesLoaded));
    this.selectedWorkingAreaId$ = this.rootStore.pipe(select(fromRoot.selectSelectedWorkingAreaId));
    this.selectedWorkingAreaId$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(workAreaId => {
      this.rootStore.dispatch(fromSettings.loadLastIpstMessageResolve({ workAreaId }));
    });
    this.ipstLoadLastMessageResolve$ = this.rootStore.pipe(
      select(fromSettings.selectIpstLastMessageResolved)
    );
  }

  buildToolBarItems(): void {
    this.toolbarService.configureItems(this.TOOLBAR_ITEMS);
  }

  onSelectionChange(id: string): void {
    this.openTab = id;
  }

  initializeSubscriptions(): void {
    this.rootStore
      .pipe(
        select(fromRoot.getRouterInfo),
        switchMap(({ params: { organization } }) =>
          this.rootStore.pipe(
            select(fromRoot.selectOrganizationByName(organization)),
            filterUndefined(),
            take(1)
          )
        )
      )
      .pipe(take(1))
      .subscribe(org => {
        this.selectedOrganization = org;
        this.getData(org);
      });
    this.rootStore
      .pipe(select(fromRoot.selectReducedSelectedWorkingArea), takeUntil(this.ngUnsubscribe))
      .subscribe(workingArea => {
        if (workingArea) {
          this.selectedWorkingArea = workingArea;
          this.populateForm();
          this.cdRef.markForCheck();
        }
      });

    this.rootStore.dispatch(
      fromRoot.showHideEditToggle({
        isVisible: this.permissionService.actionAllowedInOrg(
          AtsActions.IpstSettingsEdit,
          this.selectedOrganization?.id ?? ''
        ),
      })
    );
    this.isEditMode$ = this.rootStore.pipe(select(fromRoot.selectIsEditMode));
  }

  getData(selectedOrganization: OrganizationDto): void {
    this.ipstService
      .getIpstSettings(selectedOrganization)
      .pipe(take(1))
      .subscribe(data => {
        this.generalInformation = this.convertToIpstSettings(data);
        this.populateForm();
      });
  }

  convertToIpstSettings(data: IpstSettingsDto): IpstSettings {
    const ipstSettings: IpstSettings = {
      url: data.url,
      user: data.user,
      password: data.password,
      organizationId: data.organizationId,
      workingAreaSettings: data.workingAreaSettings.map(workingAreaDto => ({
        akz: workingAreaDto.akz,
        defaultRecipientKey: workingAreaDto.defaultRecipientKey,
        enabled: workingAreaDto.enabled,
        workingAreaId: workingAreaDto.workingAreaId,
        recipientKeys: workingAreaDto.recipientKeys,
        errorLanguage: workingAreaDto.errorLanguage,
        workingArea: workingAreaDto.akz,
      })),
    };
    return ipstSettings;
  }

  patchForm(): void {
    const generalInfo = {
      url: this.generalInformation.url,
      user: this.generalInformation.user,
      password: this.generalInformation.password,
      organizationId: this.generalInformation.organizationId,
    };

    const ipstSettings = {
      akz: this.ipstWorkingAreaSettingV2Dto.akz,
      enabled: this.ipstWorkingAreaSettingV2Dto.enabled,
      errorLanguage: this.ipstWorkingAreaSettingV2Dto.errorLanguage,
    };

    this.mainForm.patchValue({
      generalInformation: generalInfo,
      ipstSettings: ipstSettings,
    });

    this.cdRef.markForCheck();
  }

  populateForm(): void {
    const wa = this.generalInformation.workingAreaSettings.find(
      waID => waID.workingAreaId === this.selectedWorkingArea?.id
    );
    if (wa) {
      this.ipstWorkingAreaSettingV2Dto = {
        akz: wa.akz,
        enabled: wa.enabled,
        errorLanguage: wa.errorLanguage,
        defaultRecipientKey: wa.defaultRecipientKey,
        recipientKeys: wa.recipientKeys,
        workingArea: wa.workingArea,
        workingAreaId: wa.workingAreaId,
      };
    }

    this.originalIpstWorkingAreaSettingV2Dto = objectHelper.cloneDeep(
      this.ipstWorkingAreaSettingV2Dto
    );
    this.originalGeneralInformation = objectHelper.cloneDeep(this.generalInformation);
    this.patchForm();
  }

  onCancel(): void {
    this.generalInformation = objectHelper.cloneDeep(this.originalGeneralInformation);
    this.ipstWorkingAreaSettingV2Dto = objectHelper.cloneDeep(
      this.originalIpstWorkingAreaSettingV2Dto
    );
    this.patchForm();
    this.cdRef.markForCheck();
  }

  onSave(): void {
    this.generalInformation.workingAreaSettings =
      this.originalGeneralInformation.workingAreaSettings;
    this.originalGeneralInformation = objectHelper.cloneDeep(this.generalInformation);
    this.originalIpstWorkingAreaSettingV2Dto = objectHelper.cloneDeep(
      this.ipstWorkingAreaSettingV2Dto
    );
    if (!this.generalInformation.workingAreaSettings) {
      this.generalInformation.workingAreaSettings = [];
    }

    const existingWorkingArea = this.generalInformation.workingAreaSettings.findIndex(
      setting => setting.workingAreaId === this.ipstWorkingAreaSettingV2Dto.workingAreaId
    );

    if (existingWorkingArea !== -1) {
      this.generalInformation.workingAreaSettings[existingWorkingArea] =
        this.ipstWorkingAreaSettingV2Dto;
    } else {
      this.generalInformation.workingAreaSettings.push(this.ipstWorkingAreaSettingV2Dto);
    }

    this.ipstService
      .updateIpstAlertNowSettings(
        this.generalInformation,
        this.selectedOrganization,
        this.selectedWorkingArea?.id
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.toastService.createSuccessToast('settings.ipst.saveSuccessMessage');
        this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: false }));
        this.hasChanges = false;
      });
    const checkForChangesErrorMessagesTuggerTrain = this.checkForChangesErrorMessagesTuggerTrain();
    if (checkForChangesErrorMessagesTuggerTrain) {
      this.rootStore.dispatch(
        fromSettings.toggleIncludeTuggerTrainErrors({
          toggle: this.editedTuggerTrainErrorsToggle.isToggledOn,
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.rootStore.dispatch(fromRoot.showHideEditToggle({ isVisible: false }));
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onEditFormChanges(): void {
    this.initializeFormChangeSubscriptions();
  }

  initializeFormChangeSubscriptions(): void {
    this.subscribeToFormStatusChanges();
    this.subscribeToFormValueChanges();
  }

  subscribeToFormStatusChanges(): void {
    this.mainForm.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(status => {
      this.editBarService.setIsFormValid(status === 'VALID');
    });
  }

  subscribeToFormValueChanges(): void {
    this.mainForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(formValue => {
      this.updateGeneralInformation(formValue.generalInformation);
      this.updateIpstWorkingAreaSetting(formValue.ipstSettings);
      this.checkForChangesInForms();
    });
  }

  updateGeneralInformation(generalInfo: IpstSettings): void {
    this.generalInformation = {
      ...this.generalInformation,
      url: generalInfo.url,
      user: generalInfo.user,
      password: generalInfo.password,
      organizationId:
        this.selectedOrganization?.id || this.originalGeneralInformation.organizationId,
    };
  }

  updateIpstWorkingAreaSetting(ipstSettings: IpstWorkingAreaSetting): void {
    this.ipstWorkingAreaSettingV2Dto = {
      ...this.ipstWorkingAreaSettingV2Dto,
      akz: ipstSettings.akz,
      enabled: ipstSettings.enabled,
      errorLanguage: ipstSettings.errorLanguage,
      workingAreaId:
        this.selectedWorkingArea?.id || this.originalIpstWorkingAreaSettingV2Dto.workingAreaId,
      workingArea:
        this.selectedWorkingArea?.name || this.originalIpstWorkingAreaSettingV2Dto.workingArea,
    };
  }

  checkForChangesInForms(): void {
    const generalInfoChanged = !isEqual(this.generalInformation, this.originalGeneralInformation);
    const workingAreaSettingChanged = !isEqual(
      this.ipstWorkingAreaSettingV2Dto,
      this.originalIpstWorkingAreaSettingV2Dto
    );

    this.hasChanges = generalInfoChanged || workingAreaSettingChanged;
    this.editBarService.setHasChanges(this.hasChanges);
  }

  checkForChangesInToggle(): void {
    const errorMessagesTuggerTrain = !isEqual(
      this.editedTuggerTrainErrorsToggle.isToggledOn,
      this.originalTuggerTrainErrorsToggle.isToggledOn
    );
    this.hasChanges = errorMessagesTuggerTrain;
    this.editBarService.setHasChanges(this.hasChanges);
  }

  onSaveIncludeTuggerTrainErrors(includeTuggerTrainErrorsToggle: boolean): void {
    this.editedTuggerTrainErrorsToggle.isToggledOn = includeTuggerTrainErrorsToggle;
    this.checkForChangesInToggle();
  }

  checkForChangesErrorMessagesTuggerTrain(): boolean {
    return (
      this.editedTuggerTrainErrorsToggle.isToggledOn !==
      this.originalTuggerTrainErrorsToggle.isToggledOn
    );
  }

  subscribeToIpstSettingsTuggerTrainErrors(): void {
    this.ipstSettings$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(settings => {
      this.originalTuggerTrainErrorsToggle = settings.includeTuggerTrainErrorsToggle;
      this.cdRef.markForCheck();
    });
  }

  resolveAllIpstMessages(workAreaId: GuidString): void {
    this.lastIpstMessageResolved = false;
    this.cdRef.markForCheck();
    if (this.selectedOrganization) {
      this.rootStore.dispatch(
        fromSettings.resolveAllIpstMessages({
          workAreaId: workAreaId,
          org: this.selectedOrganization,
        })
      );
      this.lastIpstMessageResolved = true;
      this.hasChanges = false;
    }
  }
}
