import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MissionTraceAbortDto, MissionTraceDto } from 'core/dtos';
import { mapMissionTraceDtosToModels } from 'core/helpers';
import { GuidString, MissionTrace, SignalRNextMessage } from 'core/models';
import { Observable, Subject, Subscription, filter, map } from 'rxjs';
import { MissionTraceState } from 'store-modules/mission-monitoring-store/reducers/mission-trace.reducer';
import { poll, pollMessageTyped } from '../helpers/polling-helper';
import { SignalRNextService } from '../signalr-next.service';
import { SignalRPackedService } from '../signalr-packed.service';
import { SignalrRoutes } from '../signalr-routes';
import { activeMissionListMessageReceived } from '../store/actions/signalr.actions';

const pollingInterval = 500;

@Injectable({
  providedIn: 'root',
})
export class MissionTraceSignalRService {
  missionTraceMessageReceivedNext = new Subject<SignalRNextMessage<MissionTraceDto>>();
  missionTraceMessageReceived = new Subject<MissionTrace>();
  missionTraceAbortMessageReceivedNext = new Subject<SignalRNextMessage<MissionTraceAbortDto>>();

  activeMissionListPollingSubscription: Subscription | null = null;

  constructor(
    private readonly signalrNextService: SignalRNextService,
    private readonly packedService: SignalRPackedService,
    private readonly store: Store<MissionTraceState>
  ) {
    this.registerConnections();
  }

  signalrSubscriberFactory(componentName: string): MissionTraceSignalrSubscriber {
    const joinMissionTraces = (): Promise<void> => {
      return this.signalrNextService.joinGroup(SignalrRoutes.MissionTraces, componentName);
    };

    return {
      joinMissionTraces,
    };
  }

  protected registerConnections(): void {
    this.signalrNextService.registerConnectionNext(
      SignalrRoutes.MissionTraces,
      this.missionTraceMessageReceivedNext
    );

    this.signalrNextService.registerConnectionNext(
      SignalrRoutes.MissionTraceAbort,
      this.missionTraceAbortMessageReceivedNext
    );
  }

  leaveMissionList(): void {
    this.activeMissionListPollingSubscription?.unsubscribe();
    this.activeMissionListPollingSubscription = null;
  }

  private startActiveMissionListPolling(waId: GuidString): Subscription {
    return pollMessageTyped(pollingInterval, t =>
      this.packedService.fetchActiveMissionList(waId, t)
    ).subscribe(message => {
      this.store.dispatch(activeMissionListMessageReceived({ message }));
    });
  }

  joinMissionTrace(missionTraceId: GuidString): Observable<MissionTrace> {
    return poll(
      pollingInterval,
      t => this.signalrNextService.fetchChangedMissionTraces(missionTraceId, t),
      MissionTraceSignalRService.name
    ).pipe(
      filter(missions => missions.length > 0),
      map(missions => {
        const missionTrace = missions[0].value;
        return mapMissionTraceDtosToModels([missionTrace])[0];
      })
    );
  }

  joinMissionList(_waId: GuidString): void {
    if (this.activeMissionListPollingSubscription) {
      // eslint-disable-next-line no-console
      console.warn('Duplicate call to joinMissionList');
    }
    this.activeMissionListPollingSubscription = this.startActiveMissionListPolling(_waId);
  }

  getConnectionId(): string | null {
    return this.signalrNextService.getConnectionId();
  }
}

export interface MissionTraceSignalrSubscriber {
  joinMissionTraces(): Promise<void>;
}
