import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { API_SERVICES } from 'core/constants';
import { IpstSettingsDto, OrganizationDto, RecipientKeyListDto } from 'core/dtos';
import { BaseHttpClient } from 'core/http/base-http-client';
import { DateString, GuidString } from 'core/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IpstSettingsService extends BaseHttpClient {
  protected apiUrl = environment.Services.Ipst;
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  private readonly ipstServicePath = API_SERVICES.Settings;

  getIpstSettings(organization?: OrganizationDto): Observable<IpstSettingsDto> {
    const path = `/${organization?.name}/${organization?.workAreas[0]?.name}${this.ipstServicePath}`;
    return this.get<IpstSettingsDto>(`${path}/${organization?.id}`);
  }

  getAllIpstSettings(organization?: OrganizationDto): Observable<RecipientKeyListDto[]> {
    const path = `/${organization?.name}/${organization?.workAreas[0]?.name}${this.ipstServicePath}`;
    return this.get<RecipientKeyListDto[]>(`${path}`);
  }

  updateIpstSettings(
    updatedIPSTDto: IpstSettingsDto,
    organization?: OrganizationDto
  ): Observable<void> {
    const path = `/${organization?.name}/${organization?.workAreas[0]?.name}${this.ipstServicePath}`;
    return this.put<void>(`${path}/${updatedIPSTDto.organizationId}`, updatedIPSTDto);
  }

  updateIpstAlertNowSettings(
    ipstAlertNowV2: IpstSettingsDto,
    selectedOrganization?: OrganizationDto,
    selectedWorkingAreaId?: GuidString
  ): Observable<void> {
    const path = `/${selectedOrganization?.name}/${selectedOrganization?.workAreas[0]?.name}${this.ipstServicePath}`;

    return this.put<void>(
      `${path}/alertNow/${ipstAlertNowV2.organizationId}/${selectedWorkingAreaId}`,
      ipstAlertNowV2
    );
  }

  resolveAllIpstMessages(workAreaId: GuidString, organization?: OrganizationDto): Observable<void> {
    const path = `/${organization?.name}/${organization?.workAreas[0]?.name}${this.ipstServicePath}`;
    return this.post<void>(`${path}/resolve/${workAreaId}`);
  }

  resolveVehicleIpstMessages(
    workAreaId: GuidString,
    vehicleName: string,
    organization?: OrganizationDto
  ): Observable<void> {
    const path = `/${organization?.name}/${organization?.workAreas[0]?.name}${this.ipstServicePath}`;
    return this.post<void>(`${path}/resolve/${workAreaId}/vehicle/${vehicleName}`);
  }

  getLastResolveVehicleIpstMessage(
    workAreaId: GuidString,
    vehicleName: string,
    organization?: OrganizationDto
  ): Observable<DateString> {
    const path = `/${organization?.name}/${organization?.workAreas[0]?.name}${this.ipstServicePath}`;
    return this.get<DateString>(`${path}/lastResolve/${workAreaId}/${vehicleName}`);
  }

  getLastResolveIpstMessage(
    workAreaId: GuidString,
    organization?: OrganizationDto
  ): Observable<DateString> {
    const path = `/${organization?.name}/${organization?.workAreas[0]?.name}${this.ipstServicePath}`;
    return this.get<DateString>(`${path}/lastResolve/${workAreaId}`);
  }
}
