<div class="d-flex two-line-cell">
  <app-traffic-light
    *ngIf="!mission?.isFinalState"
    [size]="'mediumTrafficLight'"
    data-cy="missionListTrafficLight"
    [color]="mission?.deliveryStatus | formatColor : 'deliveryStatus'"
    [toolTipTranslateKey]="'enums.DeliveryStatus.' + mission?.deliveryStatus"
    [toolTipExtraText]="
      mission?.minutesUntilDelivery ? '(' + mission?.minutesUntilDelivery + ' min)' : ''
    ">
  </app-traffic-light>
  <ds-icon
    *ngIf="mission?.isFinalState"
    data-cy="missionFinalStateIcon"
    style="margin-right: 10px"
    [tone]="isAborted ? 'neutral' : 'positive'"
    [icon]="isAborted ? 'close_circle' : 'checkbox_circle'"
    size="md"></ds-icon>

  <div class="two-line-label">
    <div class="main-label" [title]="mission?.missionName" data-cy="missionName">
      {{ mission?.missionName }}
    </div>
    <div class="info-with-icon">
      <div
        class="text-neutral"
        data-cy="missionStatus"
        [ngClass]="{ 'text-critical': mission?.missionStatus === 4 }">
        {{ mission?.missionStatus | enumTranslation : 'MissionStatus' }} {{ additionalInfo }}
      </div>
      <ds-icon
        *ngIf="displayErrorIcon"
        data-cy="vehicleErrorIcon"
        class="mx-1x"
        tone="critical"
        icon="error"
        size="sm"></ds-icon>
    </div>
  </div>

  <ds-icon
    *ngIf="displayCommentIcon"
    data-cy="missionCommentIcon"
    class="mx-1x"
    tone="neutral"
    icon="message_comment"
    size="sm"></ds-icon>

  <app-action-menu
    class="action-to-left"
    variant="horizontal"
    data-cy="missionActionMenu"
    [menuItems]="menuItems"
    [identifier]="mission"></app-action-menu>
</div>
