/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  Factsheet,
  VehicleAwarenessDto,
  VehicleDetailsSignalRDto,
  VehicleDisconnectsDto,
  VehicleMapAssociation,
  VehicleWaitingDto,
} from 'core/dtos';
import { GuidString, SignalRNextMessage } from 'core/models';
import { Subject, Subscription } from 'rxjs';
import { VehiclesFeatureState } from 'store-modules/vehicles-store';

import { poll, PollingIntervals } from '../helpers/polling-helper';
import { SignalRNextService } from '../signalr-next.service';
import { SignalRPackedService } from '../signalr-packed.service';
import { SignalrRoutes } from '../signalr-routes';
import {
  factsheetMessageReceived,
  vehicleListMessagesReceived,
  vehicleMapAssociationMessageReceived,
  vehicleMessageReceived,
  vehicleWaitingMessageReceived,
} from '../store/actions/signalr.actions';

@Injectable({
  providedIn: 'root',
})
export class VehicleSignalRService {
  lastWorkAreaId?: GuidString;
  lastMapId?: GuidString;
  currentForkliftVehicleLocation?: string;
  currentIntersectionZonePath?: string;

  vehicleDetailsMessageReceivedNext = new Subject<SignalRNextMessage<VehicleDetailsSignalRDto>>();
  vehicleMapAssociationReceivedNext = new Subject<SignalRNextMessage<VehicleMapAssociation>>();
  vehicleWaitingReceivedNext = new Subject<SignalRNextMessage<VehicleWaitingDto>>();
  vehicleDisconnectionNext = new Subject<SignalRNextMessage<VehicleDisconnectsDto>>();

  vehicleAwarenessMessageReceivedNext = new Subject<SignalRNextMessage<VehicleAwarenessDto>>();
  factsheetMessageReceivedNext = new Subject<SignalRNextMessage<Factsheet>>();
  vehicleListPollingSubscription: Subscription | null = null;

  constructor(
    private readonly signalRNextService: SignalRNextService,
    private readonly packedService: SignalRPackedService,
    private readonly store: Store<VehiclesFeatureState>
  ) {
    this.registerConnections();
  }

  signalrSubscriberFactory(componentName: string): VehicleSignalrSubscriber {
    const joinVehicleDetails = (vehicleId: GuidString): Promise<void> => {
      return this.signalRNextService.joinRoute(
        SignalrRoutes.VehicleDetails,
        vehicleId,
        componentName
      );
    };

    const leaveVehicleDetails = async (vehicleId: GuidString): Promise<void> => {
      await this.signalRNextService.leaveRoute(
        SignalrRoutes.VehicleDetails,
        vehicleId.toString(),
        componentName
      );
    };

    const joinVehicleAwarenessList = async (): Promise<void> => {
      return this.signalRNextService.joinGroup(SignalrRoutes.VehicleAwarenessList, componentName);
    };

    const leaveVehicleAwarenessList = async (): Promise<void> => {
      await this.signalRNextService.leaveRoute(
        SignalrRoutes.VehicleAwarenessList,
        null,
        componentName
      );
    };

    const joinVehicleMapAssociationGroup = async (workAreaId: GuidString): Promise<void> => {
      return this.signalRNextService.joinRoute(
        SignalrRoutes.VehicleMapAssociation,
        workAreaId,
        componentName
      );
    };

    const leaveVehicleMapAssociationGroup = async (): Promise<void> => {
      await this.signalRNextService.leaveGroupOfCurrentWorkArea(
        SignalrRoutes.VehicleMapAssociation,
        componentName
      );
    };

    const joinVehicleWaiting = (vehicleId: GuidString): Promise<void> => {
      return this.signalRNextService.joinRoute(
        SignalrRoutes.VehicleWaiting,
        vehicleId,
        componentName
      );
    };

    const leaveVehicleWaiting = (vehicleId: GuidString): Promise<void> => {
      return this.signalRNextService.leaveRoute(
        SignalrRoutes.VehicleWaiting,
        vehicleId.toString(),
        componentName
      );
    };

    const joinVehicleDisconnections = (): Promise<void> => {
      return this.signalRNextService.joinRoute(
        SignalrRoutes.VehicleDisconnections,
        null,
        componentName
      );
    };

    const leaveVehicleDisconnections = (): Promise<void> => {
      return this.signalRNextService.leaveRoute(
        SignalrRoutes.VehicleDisconnections,
        null,
        componentName
      );
    };

    const joinVehicleFactsheet = (workAreaId: GuidString): Promise<void> => {
      return this.signalRNextService.joinRoute(
        SignalrRoutes.VehicleFactsheet,
        workAreaId,
        componentName
      );
    };

    const leaveVehicleFactsheet = (): Promise<void> => {
      return this.signalRNextService.leaveRoute(
        SignalrRoutes.VehicleFactsheet,
        null,
        componentName
      );
    };

    return {
      joinVehicleDetails,
      leaveVehicleDetails,
      joinVehicleAwarenessList,
      leaveVehicleAwarenessList,
      joinVehicleMapAssociationGroup,
      leaveVehicleMapAssociationGroup,
      joinVehicleWaiting,
      leaveVehicleWaiting,
      joinVehicleDisconnections,
      leaveVehicleDisconnections,
      joinVehicleFactsheet,
      leaveVehicleFactsheet,
    };
  }

  joinVehicleList(waId: GuidString, mapId: GuidString | undefined): void {
    if (this.vehicleListPollingSubscription) {
      // eslint-disable-next-line no-console
      console.warn('Duplicate call to joinVehicleList');
    }
    this.vehicleListPollingSubscription = this.startVehicleListPolling(waId, mapId ?? null);
  }

  leaveVehicleList(): void {
    this.vehicleListPollingSubscription?.unsubscribe();
    this.vehicleListPollingSubscription = null;
  }

  hasJoinedVehicleDetailsRoute(vehicleId: GuidString): boolean {
    return this.signalRNextService.hasRouteJoinedGroup(SignalrRoutes.VehicleDetails, vehicleId);
  }

  protected registerConnections(): void {
    this.signalRNextService.registerConnectionNext(
      SignalrRoutes.VehicleDetails,
      this.vehicleDetailsMessageReceivedNext
    );

    this.signalRNextService.registerConnectionNext(
      SignalrRoutes.VehicleAwarenessList,
      this.vehicleAwarenessMessageReceivedNext
    );

    this.signalRNextService.registerConnectionNext(
      SignalrRoutes.VehicleMapAssociation,
      this.vehicleMapAssociationReceivedNext
    );

    this.signalRNextService.registerConnectionNext(
      SignalrRoutes.VehicleWaiting,
      this.vehicleWaitingReceivedNext
    );

    this.signalRNextService.registerConnectionNext(
      SignalrRoutes.VehicleDisconnections,
      this.vehicleDisconnectionNext
    );

    this.vehicleMapAssociationReceivedNext.subscribe(vehicleMessage => {
      this.store.dispatch(vehicleMapAssociationMessageReceived({ vehicleMessage }));
    });

    this.vehicleDetailsMessageReceivedNext.subscribe(vehicleMessage => {
      this.store.dispatch(vehicleMessageReceived({ vehicleMessage }));
    });

    this.vehicleWaitingReceivedNext.subscribe(vehicleMessage => {
      this.store.dispatch(vehicleWaitingMessageReceived({ vehicleMessage }));
    });

    this.signalRNextService.registerConnectionNext(
      SignalrRoutes.VehicleFactsheet,
      this.factsheetMessageReceivedNext
    );

    this.factsheetMessageReceivedNext.subscribe(factsheetMessage => {
      this.store.dispatch(factsheetMessageReceived({ factsheetMessage }));
    });
  }

  private startVehicleListPolling(waId: GuidString, mapId: GuidString | null): Subscription {
    return poll(
      PollingIntervals.VehicleList,
      t => this.packedService.fetchVehicleList(waId, mapId, t),
      VehicleSignalRService.name
    ).subscribe(vehicleMessages => {
      this.store.dispatch(vehicleListMessagesReceived({ vehicleMessages }));
    });
  }
}

export interface VehicleSignalrSubscriber {
  joinVehicleDetails(vehicleId: GuidString): Promise<void>;
  leaveVehicleDetails(vehicleId: GuidString): Promise<void>;
  joinVehicleAwarenessList(): Promise<void>;
  leaveVehicleAwarenessList(): Promise<void>;
  joinVehicleMapAssociationGroup(workAreaId: GuidString): Promise<void>;
  leaveVehicleMapAssociationGroup(): Promise<void>;
  joinVehicleWaiting(vehicleId: GuidString): Promise<void>;
  leaveVehicleWaiting(vehicleId: GuidString): Promise<void>;
  joinVehicleDisconnections(): Promise<void>;
  leaveVehicleDisconnections(): Promise<void>;
  joinVehicleFactsheet(workAreaId: GuidString): Promise<void>;
  leaveVehicleFactsheet(): Promise<void>;
}
