<h3>{{ 'settings.ipstAlertNowSettings.heading' | translate }}</h3>
<ds-tabs [(activeTabId)]="activeTabId" (activeTabIdChange)="onSelectionChange($event)">
  <ds-tabs-label data-cy="ipstServiceTab" for="ipstServiceTab">
    {{ 'settings.ipstAlertNowSettings.tabs.ipstService' | translate }}
  </ds-tabs-label>
  <ds-tabs-content id="ipstServiceTab">
    <div>
      <h5>{{ 'settings.ipstAlertNowSettings.general' | translate }}</h5>

      <app-ipst-alertnow-general-info-view
        *ngIf="(isEditMode$ | async) === false"
        [generalInformation]="generalInformation">
      </app-ipst-alertnow-general-info-view>
      <app-ipst-alertnow-settings-view
        *ngIf="(isEditMode$ | async) === false"
        [selectedWorkingAreaId]="selectedWorkingAreaId$ | async"
        [ipstWorkingAreaSettingV2Dto]="ipstWorkingAreaSettingV2Dto"
        [lastIpstMessageResolved]="ipstLoadLastMessageResolve$ | async"
        (resolveAllIpstMessages)="resolveAllIpstMessages($event)"></app-ipst-alertnow-settings-view>
      <app-ipst-alertnow-error-messages-view
        *ngIf="(isEditMode$ | async) === false"
        [ipstServiceFeatures]="ipstSettings$ | async"
        [ipstServiceFeaturesLoaded]="ipstSettingsLoaded$ | async">
      </app-ipst-alertnow-error-messages-view>
      <app-ipst-alertnow-general-info-edit
        *ngIf="isEditMode$ | async"
        [formControl]="$any(mainForm.get('generalInformation'))"
        ngDefaultControl>
      </app-ipst-alertnow-general-info-edit>
      <app-ipst-alertnow-settings-edit
        *ngIf="isEditMode$ | async"
        [formControl]="$any(mainForm.get('ipstSettings'))"></app-ipst-alertnow-settings-edit>
      <app-ipst-alertnow-error-messages-edit
        *ngIf="isEditMode$ | async"
        [ipstServiceFeatures]="ipstSettings$ | async"
        [ipstServiceFeaturesLoaded]="ipstSettingsLoaded$ | async"
        (saveIncludeTuggerTrainErrors)="
          onSaveIncludeTuggerTrainErrors($event)
        "></app-ipst-alertnow-error-messages-edit>
    </div>
  </ds-tabs-content>
</ds-tabs>
