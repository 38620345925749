import { createSelector } from '@ngrx/store';
import { EMPTY_GUID } from 'core/constants';
import { GuidString, SoftwareUpdateStatus, VehicleType } from 'core/models';
import * as fromErrors from 'store-modules/errors-store';
import * as fromMapStore from 'store-modules/maps-store';
import * as fromMonitoring from 'store-modules/mission-monitoring-store';

import { VehicleDto } from 'core/dtos';
import { convertToFilteredTreeTableVehicles } from 'store-modules/vehicles-store/selectors/vehicles-treetable.selectors.helpers';
import { selectFleetEntities, selectSelectedFleetId } from './fleets.selectors';
import {
  selectAllActiveVehicles,
  selectAllVehicles,
  selectSelectedVehicle,
} from './vehicles.selectors';
import { convertToReducedVehicle } from './vehicles.selectors.helpers';

const checkNoMap = (vehicle: VehicleDto) => vehicle.vehicleType === VehicleType.Forklift;
const checkWithMap = (vehicle: VehicleDto, mapId: GuidString) =>
  (vehicle.vehicleType === VehicleType.UnitLoad || vehicle.vehicleType === VehicleType.U_AGV) &&
  vehicle?.map?.id === mapId;
const checkWithMapOrEmptyId = (vehicle: VehicleDto, mapId: GuidString) =>
  vehicle.vehicleType === VehicleType.TuggerTrain &&
  (vehicle?.map?.id === mapId || vehicle?.map?.id === EMPTY_GUID);

export const selectActiveVehiclesBySelectedMap = createSelector(
  selectAllActiveVehicles,
  fromMapStore.selectMapId,
  (vehicles, mapId) => {
    return vehicles.filter(
      v => checkNoMap(v) || checkWithMap(v, mapId) || checkWithMapOrEmptyId(v, mapId)
    );
  }
);

export const selectSelectedReducedVehicle = createSelector(
  selectSelectedVehicle,
  fromMapStore.selectAllZoneSets,
  (vehicle, zoneSets) => {
    return vehicle ? convertToReducedVehicle(vehicle, zoneSets) : undefined;
  }
);

export const selectAllActiveReducedVehicles = createSelector(
  selectAllActiveVehicles,
  fromMapStore.selectAllZoneSets,
  (activeVehicles, zoneSets) => {
    return activeVehicles.map(vehicle => convertToReducedVehicle(vehicle, zoneSets));
  }
);

export const selectAllActiveUnitLoadVehiclesForSelectedMap = createSelector(
  selectAllActiveReducedVehicles,
  fromMapStore.selectMapId,
  (activeVehicles, mapId) => {
    return activeVehicles.filter(v => v.mapId === mapId && v.vehicleType === VehicleType.UnitLoad);
  }
);

export const selectAllVehiclesForSelectedMap = createSelector(
  selectAllActiveReducedVehicles,
  fromMapStore.selectMapId,
  (activeVehicles, mapId) => {
    return activeVehicles.filter(v => v.mapId === mapId);
  }
);

export const selectVehiclesForTreeTableByFilter = createSelector(
  selectAllActiveReducedVehicles,
  fromErrors.selectAllErrors,
  fromMapStore.selectAllMaps,
  fromMonitoring.selectAllMissionListItems,
  (reducedVehicles, allErrors, allMaps, missionTraces) => {
    return convertToFilteredTreeTableVehicles(reducedVehicles, allErrors, allMaps, missionTraces);
  }
);

export const selectSelectedFleetWithVehicles = createSelector(
  selectFleetEntities,
  selectSelectedFleetId,
  selectAllVehicles,
  fromMapStore.selectAllMaps,
  (entities, fleetId, allVehicles, allMaps) => {
    if (fleetId) {
      const fleet = entities[String(fleetId)];
      if (fleet) {
        const fleetMap = allMaps.filter(map => map.id === fleet.mapId)[0];
        if (fleetMap) {
          return {
            id: fleet.id,
            name: fleet.name,
            comments: fleet.comments,
            mapName: fleetMap.name,
            mapId: fleet.mapId,
            loadType: fleet.loadType,
            vehicles: allVehicles.filter(vehicle => vehicle.fleetId === fleet.id),
            recipientKey: fleet.recipientKey,
          };
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }
);

export const selectSoftwareUpdateInstallingAndUpdatingCount = createSelector(
  selectAllActiveVehicles,
  activeVehicles => {
    return activeVehicles.filter(
      v =>
        v.softwareUpdateStatus === SoftwareUpdateStatus.Downloading ||
        v.softwareUpdateStatus === SoftwareUpdateStatus.Installing
    ).length;
  }
);
