import { Injectable } from '@angular/core';
import { GuidString } from 'core/models';
import { Subject, Subscription } from 'rxjs';

import { VehicleLocationUpdate, VehicleZoneUpdate } from 'core/dtos';
import { MapPollingUnpackHelper } from '../helpers/map-polling.helper';
import { poll, PollingIntervals } from '../helpers/polling-helper';
import { SignalRPackedService } from '../signalr-packed.service';

@Injectable({
  providedIn: 'root',
})
export class MapVehicleSignalRService {
  lastWorkAreaId?: GuidString;
  lastMapId?: GuidString;
  mapDataPollingSubscription: Subscription | null = null;

  vehicleLocationUpdate = new Subject<VehicleLocationUpdate[]>();
  vehicleZoneUpdate = new Subject<VehicleZoneUpdate[]>();

  constructor(private readonly packedService: SignalRPackedService) {}

  // #region Vehicle Locations
  async joinVehicleLocationsWorkAreaGroup(workAreaId: GuidString): Promise<void> {
    this.stopPolling();
    this.lastWorkAreaId = workAreaId;
    this.tryStartPolling();

    return Promise.resolve();
  }

  async leaveVehicleLocationsWorkAreaGroup(): Promise<void> {
    this.stopPolling();

    return Promise.resolve();
  }

  async joinVehicleLocationsMapGroup(mapId: GuidString): Promise<void> {
    this.stopPolling();
    this.lastMapId = mapId;
    this.tryStartPolling();

    return Promise.resolve();
  }

  async leaveVehicleLocationsMapGroup(): Promise<void> {
    this.stopPolling();
    return Promise.resolve();
  }
  // #endregion

  // #region Polling
  private tryStartPolling(): void {
    if (this.mapDataPollingSubscription != null) return;

    if (this.lastWorkAreaId && this.lastMapId)
      this.mapDataPollingSubscription = this.startPolling(this.lastWorkAreaId, this.lastMapId);
  }

  private stopPolling(): void {
    this.mapDataPollingSubscription?.unsubscribe();
    this.mapDataPollingSubscription = null;
  }

  private startPolling(waId: GuidString, mapId: GuidString): Subscription {
    return poll(
      PollingIntervals.MapVehicles,
      t => this.packedService.fetchMapData(waId, mapId, t),
      MapVehicleSignalRService.name
    ).subscribe(updates => {
      this.vehicleLocationUpdate.next(updates.map(MapPollingUnpackHelper.unpackVehicle));

      this.vehicleZoneUpdate.next(
        updates.map(item => ({
          vehicleId: item.id,
          conflictArea: MapPollingUnpackHelper.unpackConflictArea(waId, item.id, item.conflictZone),
          intersectionZone: MapPollingUnpackHelper.unpackZonePath(
            mapId,
            item.id,
            item.intersectionZonePath
          ),
        }))
      );
    });
  }
  // #endregion
}
