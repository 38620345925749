import { FeatureToggle } from 'core/dtos';

export type EnableNavigationLayerDto = FeatureToggle;
export type EnableVehicleSmoothingSettingsDto = FeatureToggle;

export interface MapManagerFeatures {
  enableNavigationLayerToggle: EnableNavigationLayerDto;
  enableVehicleSmoothingToggle: EnableVehicleSmoothingSettingsDto;
}

export function getDefaultMapManagerFeatures(): MapManagerFeatures {
  return {
    enableNavigationLayerToggle: {
      isToggledOn: true,
      dateUpdated: null,
    },
    enableVehicleSmoothingToggle: {
      isToggledOn: true,
      dateUpdated: null,
    },
  };
}
