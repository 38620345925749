import {
  PathEdgeDto,
  VehicleConflictAreaDimensions,
  VehicleLocationUpdate,
  VehicleMap,
} from 'core/dtos';
import {
  ActionStateActionStatus,
  BatteryLevelStatus,
  DateString,
  GuidString,
  HardwareVersion,
  LoadType,
  Pose2D,
  Pose2DO,
  SoftwareUpdateStatus,
  Trailer,
  Vector2D,
  VehicleAvailability,
  VehicleInterfaceType,
  VehicleMode,
  VehicleStatus,
  VehicleType,
} from 'core/models';

export interface BaseVehicleDto {
  ipAddress: string;
  internalIdentifier: string;
  interfaceType: VehicleInterfaceType;
  name: string;
  maintenanceModeEnabled: boolean;
  isErrorForwardingEnabled: boolean;
  vehicleType: VehicleType;
  hardwareVersion: HardwareVersion;
  loadType: LoadType;
  supportedLoadTypes: LoadType[];
}

export interface BasicVehicleDto {
  id: GuidString;
  workAreaId: GuidString;
  batteryLevel: number;
  batteryLevelStatus: BatteryLevelStatus;
  status: VehicleStatus;
  name: string;
}

export interface VehicleDto extends BaseVehicleDto {
  id: GuidString;
  workAreaId: GuidString;
  availability: VehicleAvailability;
  batteryLevel: number;
  batteryLevelStatus: BatteryLevelStatus;
  fleetId: GuidString | null;
  fleetName?: string;
  forkLength: number;
  hasError: boolean;
  initializationDateTime: DateString;
  isBusy: boolean;
  isConnected: boolean;
  isCharging: boolean;
  isLoaded: boolean;
  isPaused: boolean;
  isRetired: boolean;
  isSwitchedOff: boolean;
  brakeTestRequired: boolean;
  status: VehicleStatus;
  lastStateMessageEnqueuedUtc?: DateString;
  lastStateMessageProcessedUtc: DateString;
  mode: VehicleMode;
  map?: MapNavigationDto;
  pose2D: Pose2DO;
  path?: Vector2D[];
  softwareVersion: string | null;
  softwareVersionChangedDateUtc?: DateString;
  softwareUpdateStatus: SoftwareUpdateStatus;
  softwareDownloadPercentage: number;
  trailers?: Trailer[] | null;
  trailerCount?: number; //This field is only updated by VehicleDetails SignalR Message
  vehicleConflictAreaDimensions?: VehicleConflictAreaDimensions; //This field is only used when TrafficAnalysis
  velocity: Velocity;
  vehicleKey: string;
  zoneSetId: GuidString;
  desiredZoneSetId: GuidString;
  hardwareVersion: HardwareVersion;
  waitEndTime?: DateString;
  metrics?: MessageMetrics;
  orderState?: DsOrderState | null;
  hideTrailers?: boolean;
  supportedLoadTypes: LoadType[];
  maps?: VehicleMap[];
  loadType: LoadType;
}

export type MergedVehicle = VehicleDto & VehicleLocationUpdate;

export interface MapNavigationDto {
  id: GuidString;
  navigationLayerId: GuidString;
}

export interface VehicleAwarenessDto {
  id: GuidString;
  isAwarenessPaused: boolean;
  map: MapNavigationDto;
  workAreaIdId: GuidString;
  pose: Pose2D;
  path: Vector2D[];
  intersectionZonePath?: PathEdgeDto[];
  lookAheadAreaPathEdges?: PathEdgeDto[];
  stoppingAreaPathEdges?: PathEdgeDto[];
  velocity: Velocity;
  isLoaded: boolean;
}

export type PackedMessageMetrics = [Date, Date, Date, Date, Date | null];

export interface MessageMetrics {
  iotHubEnqueuedTimeUtc: DateString;
  ingestEnqueuedTimeUtc: DateString;
  fleetManagerEnqueuedTimeUtc: DateString;
  signalREnqueuedTimeUtc: DateString;
  trafficManagerEnqueuedTimeUtc: DateString | null;
}

export const defaultMetrics: MessageMetrics = {
  iotHubEnqueuedTimeUtc: '',
  fleetManagerEnqueuedTimeUtc: '',
  ingestEnqueuedTimeUtc: '',
  signalREnqueuedTimeUtc: '',
  trafficManagerEnqueuedTimeUtc: null,
};

export interface DsOrderState {
  orderId: string;
  actionStates: ActionState[];
}

export interface OrderPathDto {
  orderId: string;
  path: Vector2D[];
}

export type PackedDsOrderState = [string, PackedActionState[]];

export type PackedActionState = [
  string | null,
  string | null,
  string | null,
  ActionStateActionStatus,
  string | null
];

export interface ActionState {
  actionId: GuidString;
  actionType: string;
  actionDescription: string;
  actionStatus: ActionStateActionStatus;
  resultDescription: string;
}

export const defaultActionState: ActionState = {
  actionId: '',
  actionType: '',
  actionDescription: '',
  actionStatus: ActionStateActionStatus.Waiting,
  resultDescription: '',
};

export interface VehicleListSignalrDto {
  // MessagePack object
  id: GuidString;
  timestamp: string;
  maintenanceModeEnabled: boolean;
  workAreaId: GuidString;
  batteryLevel: number;
  batteryLevelStatus: number;
  fleetId: GuidString | null;
  fleetName: string | null;
  ipAddress: string;
  lastStateMessageProcessedUtc: Date;
  internalIdentifier: string;
  name: string;
  hasError: boolean;
  isPaused: boolean;
  isCharging: boolean;
  isBusy: boolean;
  isRetired: boolean;
  isSwitchedOff: boolean;
  isConnected: boolean;
  isLoaded: boolean;
  brakeTestRequired: boolean;
  mapId: GuidString;
  vehicleType: VehicleType;
  mode: VehicleMode;
  zoneSetId: GuidString;
  desiredZoneSetId: GuidString;
  softwareVersion: string | null;
  softwareVersionChangedDateUtc: Date;
  softwareUpdateStatus: SoftwareUpdateStatus;
  softwareDownloadPercentage: number;
  availability: VehicleAvailability;
  status: VehicleStatus;
  trailerCount: number;
  metrics: PackedMessageMetrics;
  orderState: PackedDsOrderState | null;
  supportedLoadTypes: LoadType[];
}

export interface VehicleDetailsSignalRDto {
  // Route: 'vehicles/{0}'
  id: GuidString;
  timestamp: string;
  maintenanceModeEnabled: boolean;
  isErrorForwardingEnabled: boolean;
  workAreaId: GuidString;
  batteryLevel: number;
  batteryLevelStatus: BatteryLevelStatus;
  ipAddress: string;
  lastStateMessageProcessedUtc: DateString;
  lastStateMessageEnqueuedUtc: DateString;
  internalIdentifier: string;
  name: string;
  hasError: boolean;
  isPaused: boolean;
  isCharging: boolean;
  isBusy: boolean;
  isRetired: boolean;
  isSwitchedOff: boolean;
  isConnected: boolean;
  isLoaded: boolean;
  brakeTestRequired: boolean;
  mapId: GuidString;
  vehicleType: VehicleType;
  mode: VehicleMode;
  zoneSetId: GuidString;
  desiredZoneSetId: GuidString;
  softwareVersion: GuidString;
  availability: VehicleAvailability;
  status: VehicleStatus;
  trailerCount: number;
}

export interface Velocity {
  vx: number;
  vy: number;
  omega: number;
}
