import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AtsTranslationService } from 'core/services';
import { EmulatorSettingsComponent } from 'modules/settings/components/emulator-settings/emulator-settings.component';
import { EnvironmentUsersComponent } from 'modules/settings/components/environment-users/environment-users.component';
import { EvacuationModeToggleComponent } from 'modules/settings/components/evacuation-mode-toggle/evacuation-mode-toggle.component';
import { InformationBarComponent } from 'modules/settings/components/information-bar/information-bar.component';
import { MissionErrorHandlingSettingsModalComponent } from 'modules/settings/components/mission-error-handling-settings-modal/mission-error-handling-settings-modal.component';
import { ShutdownModeModalComponent } from 'modules/settings/components/shutdown-mode-modal/shutdown-mode-modal.component';
import { ShutdownModeToggleComponent } from 'modules/settings/components/shutdown-mode-toggle/shutdown-mode-toggle.component';
import { UserListComponent } from 'modules/settings/components/user-list/user-list.component';
import { WagoEvacuationDevicesConfigFeedbacksTableComponent } from 'modules/settings/components/wago-evacuation-devices-config-feedbacks-table/wago-evacuation-devices-config-feedbacks-table.component';
import { WagoEvacuationDevicesConfigTriggersTableComponent } from 'modules/settings/components/wago-evacuation-devices-config-triggers-table/wago-evacuation-devices-config-triggers-table.component';
import { EnvironmentRolesContainerComponent } from 'modules/settings/containers/environment-roles-container/environment-roles-container.component';
import { EnvironmentUsersContainerComponent } from 'modules/settings/containers/environment-users-container/environment-users-container.component';
import { WorkingAreaDeveloperSettingsComponent } from 'modules/settings/containers/working-area-developer-settings-container/working-area-developer-settings-container.component';
import { WorkingAreaSettingsComponent } from 'modules/settings/containers/working-area-settings/working-area-settings.component';
import { SharedModule } from 'shared/shared.module';
import { SettingsStoreModule } from 'store-modules/settings-store/settings-store.module';
import { AuditlogComponent } from './components/auditlog/auditlog.component';
import { ChangelogComponent } from './components/changelog/changelog.component';
import { CollectivePauseToggleComponent } from './components/collective-pause-toggle/collective-pause-toggle.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { ErrorForwardingListComponent } from './components/error-forwarding-list/error-forwarding-list.component';
import { FunctionsModalComponent } from './components/functions-modal/functions-modal.component';
import { InfobarSettingsComponent } from './components/infobar-settings/infobar-settings.component';
import { IpstGroupsEditComponent } from './components/ipst-groups-edit/ipst-groups-edit.component';
import { IpstGroupsViewComponent } from './components/ipst-groups-view/ipst-groups-view.component';
import { IpstGroupsWorkareaEditComponent } from './components/ipst-groups-workarea-edit/ipst-groups-workarea-edit.component';
import { IpstSettingsEditComponent } from './components/ipst-settings-edit/ipst-settings-edit.component';
import { IpstSettingsViewComponent } from './components/ipst-settings-view/ipst-settings-view.component';
import { LoadTypeTableComponent } from './components/load-type-table/load-type-table.component';
import { OperationComponent } from './components/operation/operation.component';
import { PermissionsCheckBoxComponent } from './components/permissions-checkbox/permissions-checkbox.component';
import { RolesAndPermissionsComponent } from './components/roles-and-permissions/roles-and-permissions.component';
import { SettingBoxComponent } from './components/setting-box/setting-box.component';
import { SettingsHeaderComponent } from './components/settings-header/settings-header.component';
import { TemporaryComponent } from './components/temporary/temporary.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { WagoEvacuationDevicesConfigModalComponent } from './components/wago-evacuation-devices-config-modal/wago-evacuation-devices-config-modal.component';
import { AllEnvironmentUsersContainerComponent } from './containers/all-environment-users-container/all-environment-users-container.component';
import { AllOrganizationUsersContainerComponent } from './containers/all-organization-users-container/all-organization-users-container.component';
import { AllWorkingAreaUsersContainerComponent } from './containers/all-working-area-users-container/all-working-area-users-container.component';
import { AuditLogContainerComponent } from './containers/auditlog-container/auditlog-container.component';
import { EnvironmentOrganizationWorkingAreaOverviewContainerComponent } from './containers/environment-organization-working-area-overview-container/environment-organization-working-area-overview-container.component';
import { ErrorForwardingListContainerComponent } from './containers/error-forwarding-list-container/error-forwarding-list-container.component';
import { FunctionsContainerComponent } from './containers/functions-container/functions-container.component';

import { IpstAlertNowErrorMessagesEditComponent } from './components/ipst-alertnow-error-messages-edit/ipst-alertnow-error-messages-edit.component';
import { IpstAlertNowErrorMessagesViewComponent } from './components/ipst-alertnow-error-messages-view/ipst-alertnow-error-messages-view.component';
import { IpstAlertNowGeneralInfoEditComponent } from './components/ipst-alertnow-general-info-edit/ipst-alertnow-general-info-edit.component';
import { IpstAlertNowGeneralInfoViewComponent } from './components/ipst-alertnow-general-info-view/ipst-alertnow-general-info-view.component';
import { IpstAlertNowSettingsEditComponent } from './components/ipst-alertnow-settings-edit/ipst-alertnow-settings-edit.component';
import { IpstAlertnowSettingsViewComponent } from './components/ipst-alertnow-settings-view/ipst-alertnow-settings-view.component';
import { IpstAlertNowSettingsContainerComponent } from './containers/ipst-alertnow-settings-container/ipst-alertnow-settings-container.component';
import { IpstSettingsContainerComponent } from './containers/ipst-settings-container/ipst-settings-container.component';
import { OrganizationSettingsComponent } from './containers/organization-settings/organization-settings.component';
import { UserRolesAndPermissionsContainerComponent } from './containers/user-roles-and-permissions-container/user-roles-and-permissions-container.component';
import translations_de from './i18n/settings-de.json';
import translations_en from './i18n/settings-en.json';
import { InfobarSettingsPageComponent } from './page/infobar-settings/infobar-settings-page.component';
import { UserRolesAndPermissionPageComponent } from './page/user-roles-and-permission-page.component';
import { LatestDatePipe } from './pipes/latest-date.pipe';
import { SettingsRoutingModule } from './settings-routing.module';

@NgModule({
  declarations: [
    ErrorForwardingListComponent,
    ErrorForwardingListContainerComponent,
    EnvironmentRolesContainerComponent,
    EnvironmentUsersContainerComponent,
    EnvironmentUsersComponent,
    WorkingAreaSettingsComponent,
    InformationBarComponent,
    WorkingAreaDeveloperSettingsComponent,
    EmulatorSettingsComponent,
    OrganizationSettingsComponent,
    IpstSettingsContainerComponent,
    IpstSettingsEditComponent,
    IpstSettingsViewComponent,
    LoadTypeTableComponent,
    IpstGroupsViewComponent,
    IpstGroupsEditComponent,
    SettingsHeaderComponent,
    AllEnvironmentUsersContainerComponent,
    UserRolesAndPermissionPageComponent,
    EnvironmentOrganizationWorkingAreaOverviewContainerComponent,
    AllOrganizationUsersContainerComponent,
    AllWorkingAreaUsersContainerComponent,
    InfobarSettingsComponent,
    InfobarSettingsPageComponent,
    FunctionsContainerComponent,
    OperationComponent,
    ConfigurationComponent,
    TemporaryComponent,
    ChangelogComponent,
    FunctionsModalComponent,
    ShutdownModeModalComponent,
    ShutdownModeToggleComponent,
    ToggleComponent,
    EvacuationModeToggleComponent,
    MissionErrorHandlingSettingsModalComponent,
    AuditLogContainerComponent,
    AuditlogComponent,
    WagoEvacuationDevicesConfigModalComponent,
    UserListComponent,
    RolesAndPermissionsComponent,
    WagoEvacuationDevicesConfigTriggersTableComponent,
    WagoEvacuationDevicesConfigFeedbacksTableComponent,
    IpstGroupsWorkareaEditComponent,
    CollectivePauseToggleComponent,
    SettingBoxComponent,
    LatestDatePipe,
    PermissionsCheckBoxComponent,
    UserRolesAndPermissionsContainerComponent,
    IpstAlertNowSettingsContainerComponent,
    IpstAlertNowGeneralInfoViewComponent,
    IpstAlertNowGeneralInfoEditComponent,
    IpstAlertNowErrorMessagesViewComponent,
    IpstAlertNowErrorMessagesEditComponent,
    IpstAlertnowSettingsViewComponent,
    IpstAlertNowSettingsEditComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [SharedModule, SettingsRoutingModule, SettingsStoreModule],
  exports: [
    InformationBarComponent,
    WagoEvacuationDevicesConfigTriggersTableComponent,
    WagoEvacuationDevicesConfigFeedbacksTableComponent,
  ],
})
export class SettingsModule {
  constructor(translator: AtsTranslationService) {
    translator.loadTranslations(translations_en, translations_de);
  }
}
