import { MessageType, SignalRNextMessage } from 'core/models';
import { maxBy } from 'lodash';
import { Observable, exhaustMap, filter, interval, tap } from 'rxjs';

export const PollingIntervals = {
  VehicleList: 200,
  MapVehicles: 67, // 15 fps
};

export function poll<T extends { timestamp: string }>(
  intervalMs: number,
  func: (t: string | null) => Promise<T[]>,
  source: string
): Observable<T[]> {
  let lastKnownBackendTimestamp: string | null = null;

  return interval(intervalMs).pipe(
    exhaustMap(async _ =>
      func(lastKnownBackendTimestamp).catch(err => {
        console.error(`Error while calling SignalR server from ${source}`, err);
        lastKnownBackendTimestamp = null; // If the backend restarts, we should reset this
        return [];
      })
    ),
    filter(arr => Array.isArray(arr) && arr?.length > 0),
    tap(updates => {
      lastKnownBackendTimestamp = maxBy(updates, 'timestamp')?.timestamp ?? null;
    })
  );
}

export function pollMessageTyped<T extends { timestamp: string }>(
  intervalMs: number,
  func: (t: string | null) => Promise<SignalRNextMessage<T[]>>
): Observable<SignalRNextMessage<T[]>> {
  let lastKnownBackendTimestamp: string | null = null;

  return interval(intervalMs).pipe(
    exhaustMap(async _ =>
      func(lastKnownBackendTimestamp).catch(err => {
        console.error('Error while calling SignalR server', err);
        lastKnownBackendTimestamp = null; // If the backend restarts, we should reset this
        return {
          type: MessageType.Modified,
          payload: [],
        };
      })
    ),
    filter(msg => Array.isArray(msg.payload) && msg.payload?.length > 0),
    tap(updates => {
      lastKnownBackendTimestamp = maxBy(updates.payload, 'timestamp')?.timestamp ?? null;
    })
  );
}
