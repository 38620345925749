/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { DataPublisherService } from 'core/api-services';
import { EMPTY_GUID, LOCALSTORAGE } from 'core/constants';
import {
  AdxVehicleMessageRecord,
  CollisionPoint,
  EdgeDto,
  IntersectionCollisionPoint,
  LayoutDto,
  MapDto,
  MergedVehicle,
  NavigationLayerResponseModel,
  NavigationLayerStorageModel,
  NodeDto,
  NodeGroupDto,
  OpcuaDeviceResponseModel,
  PillarsGridDto,
  PoiDto,
  RouteConfigurationDto,
  RuleDto,
  VehicleZoneUpdate,
  ZoneSetDto,
} from 'core/dtos';
import { filterUndefined } from 'core/helpers';
import {
  BatteryLevelStatus,
  GraphLayout,
  GuidString,
  HardwareVersion,
  LoadType,
  LoadTypeConfigurationDto,
  MapMode,
  Mission,
  MissionTrace,
  SoftwareUpdateStatus,
  VehicleAvailability,
  VehicleInterfaceType,
  VehicleStatus,
  WorkingArea,
} from 'core/models';
import { StorageService } from 'core/services/storage.service';
import { SignalRNextService } from 'core/signalR/signalr-next.service';
import { isEqual } from 'lodash';
import { MissionListInputModel } from 'modules/jobs/mission-monitoring/components/mission-list/mission-list-model';
import {
  BehaviorSubject,
  combineLatest,
  delay,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  mergeMap,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

import { MapContainerDetails } from '../models';
import { SelectedMapData } from '../models/map-data';
import { MapCommunicationService } from './map-communication.service';
import {
  mapAdxRecordToCollision,
  setUpdateToVehicles,
  setVehicleDefaultValues,
} from './map-data.helper';

import { ofType } from '@ngrx/effects';
import { MapVehicleSignalRService } from 'core/signalR/modules';
import * as fromGraphManager from 'store-modules/graph-manager';
import * as fromMaps from 'store-modules/maps-store';
import * as fromMissionMonitoring from 'store-modules/mission-monitoring-store';
import * as fromOpcuaDevices from 'store-modules/opcua-devices-store';
import * as fromPois from 'store-modules/pois-store';
import * as fromProcessConfigurator from 'store-modules/process-configurator-store';
import * as fromSettings from 'store-modules/settings-store';
import * as fromVehicles from 'store-modules/vehicles-store';
import * as fromRoot from 'store/index';

@Injectable({
  providedIn: 'root',
})
export class MapDataService {
  //#region Members
  private currentMap: SelectedMapData | undefined;

  mode$!: Observable<MapMode | undefined>;
  mapId$!: Observable<GuidString | undefined>;
  selectedMap$!: Observable<MapDto>;
  selectedMapEmpty$!: Observable<boolean | undefined>;
  activeZoneSet$!: Observable<ZoneSetDto | undefined>;

  allNavigationLayers$: Observable<NavigationLayerResponseModel[]> = of([]);
  allZoneSets$!: Observable<ZoneSetDto[] | undefined>;
  selectedZoneSet$!: Observable<ZoneSetDto | undefined>;
  isRouteConfigurationMode$!: Observable<boolean | undefined>;

  allPois$!: Observable<PoiDto[]>;
  activeLoadTypeConfigurations$!: Observable<LoadTypeConfigurationDto[]>;
  activeLoadTypes$!: Observable<LoadType[]>;
  pillarsGrid$!: Observable<PillarsGridDto | undefined | null>;
  allMissionsForDispatch$: Observable<Mission[] | undefined> = of([]);
  activeMissionTracesList$: Observable<MissionListInputModel[]> = of([]);
  activeMissionTraces$: Observable<MissionTrace[]> = of([]);
  selectedVehicleMission$: Observable<MissionTrace | undefined> = of();
  allDevices$: Observable<OpcuaDeviceResponseModel[]> = of([]);

  graphLayerEnabled$: Observable<boolean> = of(false);
  graphLayer$!: Observable<LayoutDto | undefined>;
  graphNodes$!: Observable<NodeDto[]>;
  graphEdges$!: Observable<EdgeDto[]>;
  graphRouteRules$!: Observable<RuleDto[]>;
  graphRouteConfigs$!: Observable<RouteConfigurationDto[] | undefined>;
  nodeGroups$: Observable<NodeGroupDto[]> = of([]);

  layerUpdatePoiStatus$!: Observable<PoiDto>;
  layerUpdatePoi$!: Observable<PoiDto>;
  layerCreatedPoi$!: Observable<PoiDto>;
  layerDeletedPoi$!: Observable<GuidString>;

  private readonly graphLayout = new BehaviorSubject<GraphLayout | undefined>(undefined);
  graphLayout$: Observable<GraphLayout | undefined> = this.graphLayout.asObservable();

  // Emitted when the map is selected and navigation layers set - Create MapContainerDetails
  private readonly mapSelected = new BehaviorSubject<SelectedMapData | undefined>(undefined);
  mapSelected$: Observable<SelectedMapData | undefined> = this.mapSelected.asObservable();

  // Emitted on map changed - Set ViewPort
  private readonly mapChanged = new BehaviorSubject<SelectedMapData | undefined>(undefined);
  mapChanged$: Observable<SelectedMapData | undefined> = this.mapChanged.asObservable();

  private readonly allVehicles = new BehaviorSubject<MergedVehicle[]>([]);
  allVehicles$: Observable<MergedVehicle[]> = this.allVehicles.asObservable();

  private readonly allCollisionPoints = new BehaviorSubject<CollisionPoint[]>([]);
  allCollisionPoints$: Observable<CollisionPoint[]> = this.allCollisionPoints.asObservable();

  private readonly allIntersectionCollisionPoints = new BehaviorSubject<
    IntersectionCollisionPoint[]
  >([]);
  allIntersectionCollisionPoints$: Observable<IntersectionCollisionPoint[]> =
    this.allIntersectionCollisionPoints.asObservable();

  get vehicleZoneUpdate$(): Observable<VehicleZoneUpdate[]> {
    return this.vehiclePollingSignalRService.vehicleZoneUpdate.asObservable();
  }

  ngUnsubscribeTrafficAnalysis = new Subject<void>();
  ngUnsubscribeLiveData = new Subject<void>();
  //#endregion

  constructor(
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly storageService: StorageService,
    private readonly vehiclePollingSignalRService: MapVehicleSignalRService,
    private readonly processChainStore: Store<fromProcessConfigurator.ProcessConfiguratorFeatureState>,
    private readonly missionMonitoringStore: Store<fromMissionMonitoring.MonitoringFeatureState>,
    private readonly mapCommunicationService: MapCommunicationService,
    private readonly dataPublisherService: DataPublisherService,
    private readonly signalRNextService: SignalRNextService,
    private readonly settingsStore: Store<fromSettings.SettingsFeatureState>,
    private readonly poisStore: Store<fromPois.PoisFeatureState>,
    private readonly actions$: ActionsSubject
  ) {
    this.subscribeToMap();

    this.subscribeToMapForSelected();
    this.subscribeToMapForChanges();

    this.subscribeToDataMode();
    this.subscribeToMapLayers();
    this.subscribeToMissions();
    this.subscribeToGraphLayout();
  }

  // #region Initialization
  private subscribeToDataMode(): void {
    this.mapCommunicationService.isTrafficAnalysis$.subscribe(isTrafficAnalysis => {
      if (isTrafficAnalysis) {
        this.ngUnsubscribeLiveData.next();
        this.ngUnsubscribeLiveData.complete();
        this.ngUnsubscribeTrafficAnalysis = new Subject<void>();

        void this.setDataForTrafficAnalysis();
      } else {
        this.ngUnsubscribeTrafficAnalysis.next();
        this.ngUnsubscribeTrafficAnalysis.complete();

        this.ngUnsubscribeLiveData.next();
        this.ngUnsubscribeLiveData.complete();
        this.ngUnsubscribeLiveData = new Subject<void>();

        this.subscribeToLiveMapData();
      }
    });
  }

  private subscribeToMap() {
    this.mode$ = this.rootStore.pipe(select(fromMaps.selectMapMode));

    this.selectedMap$ = this.rootStore.pipe(select(fromMaps.selectSelectedMap), filterUndefined());
    this.mapId$ = this.rootStore.pipe(select(fromMaps.selectMapId));
    this.selectedMapEmpty$ = this.createSelectedMapEmpty();
    this.activeZoneSet$ = this.rootStore.pipe(select(fromMaps.selectSelectedMapActiveZoneSet));
    this.allNavigationLayers$ = this.rootStore.pipe(
      select(fromMaps.selectNavigationLayersBySelectedMapId)
    );
  }

  private subscribeToMapLayers() {
    this.allPois$ = this.rootStore.pipe(select(fromPois.selectPoisBySelectedMapId));
    this.activeLoadTypes$ = this.settingsStore.pipe(select(fromSettings.selectActiveLoadTypes));
    this.activeLoadTypeConfigurations$ = this.settingsStore.pipe(
      select(fromSettings.selectActiveLoadTypeConfigurations)
    );
    this.pillarsGrid$ = this.rootStore.pipe(select(fromMaps.selectPillarsGridBySelectedMapId));
    this.allZoneSets$ = this.rootStore.pipe(select(fromMaps.selectAllZoneSets));
    this.selectedZoneSet$ = this.rootStore.pipe(select(fromMaps.selectZoneSetBySelectedZoneSetId));
    this.isRouteConfigurationMode$ = this.rootStore.pipe(select(fromMaps.isRouteConfigurationMode));

    this.allDevices$ = this.rootStore.pipe(select(fromOpcuaDevices.selectAllOpcuaDevices));

    this.layerUpdatePoiStatus$ = this.selectPoiStatusChanged();
    this.layerUpdatePoi$ = this.selectPoiChanged();
    this.layerCreatedPoi$ = this.selectPoiCreated();
    this.layerDeletedPoi$ = this.selectPoiDeleted();
  }

  private subscribeToGraphLayout() {
    this.graphLayer$ = this.rootStore.pipe(select(fromMaps.selectGraphLayersBySelectedMapId));
    this.graphNodes$ = this.rootStore.pipe(select(fromMaps.selectGraphNodesBySelectedMapId));
    this.graphEdges$ = this.rootStore.pipe(select(fromMaps.selectGraphEdgesBySelectedMapId));
    this.graphRouteRules$ = this.rootStore.pipe(
      select(fromGraphManager.selectAllRouteCustomizationRules)
    );
    this.graphRouteConfigs$ = this.rootStore.pipe(
      select(fromGraphManager.selectAllRouteConfigurations)
    );
    this.nodeGroups$ = this.rootStore.pipe(select(fromGraphManager.selectAllNodeGroups));

    this.graphLayerEnabled$ = this.rootStore.pipe(
      select(fromSettings.selectGraphManagerFeatureSettings),
      map(featureSettings => featureSettings.settings.enableGraphManager ?? false)
    );
  }

  private subscribeToMissions() {
    this.allMissionsForDispatch$ = this.processChainStore.pipe(
      select(fromProcessConfigurator.selectAllMissionsForDirectDispatch)
    );
    this.activeMissionTracesList$ = this.missionMonitoringStore.pipe(
      select(fromMissionMonitoring.selectAllMissionActiveList2)
    );
    this.activeMissionTraces$ = this.rootStore.pipe(
      select(fromMissionMonitoring.selectActiveMissions2)
    );

    this.selectedVehicleMission$ = this.missionMonitoringStore.pipe(
      select(fromMissionMonitoring.selectMissionByVehicle)
    );
  }

  private getMapEmpty(
    isMapEmpty: boolean | undefined,
    isNavigationLayersEmpty: boolean | undefined
  ): boolean | undefined {
    if (isMapEmpty === undefined) {
      return undefined;
    }

    if (isMapEmpty) {
      return true;
    }

    if (isNavigationLayersEmpty === undefined) {
      return undefined;
    }

    return isNavigationLayersEmpty;
  }

  private createSelectedMapEmpty(): Observable<boolean | undefined> {
    return combineLatest([
      this.rootStore.pipe(select(fromMaps.selectSelectedMapEmpty)),
      this.rootStore.pipe(select(fromMaps.selectNoNavigationLayersBySelectedMapId)),
    ]).pipe(
      map(([isMapEmpty, isNavigationLayersEmpty]) => {
        return this.getMapEmpty(isMapEmpty, isNavigationLayersEmpty);
      })
    );
  }

  private subscribeToMapForSelected(): void {
    combineLatest([
      this.selectedMap$.pipe(delay(1)),
      this.rootStore.pipe(select(fromMaps.selectNavigationLayersBySelectedMapId)),
      this.rootStore.pipe(select(fromSettings.selectMapSettings)),
    ])
      .pipe(
        filter(([selectedMap, layers]) => layers && layers.some(l => l.mapId === selectedMap.id)),
        map(([selectedMap, layers, settings]) => {
          const store = this.storageService.getArray<NavigationLayerStorageModel>(
            LOCALSTORAGE.NAVIGATION_LAYER_CARD
          );

          const storedCards = store.filter(c => c.mapId === selectedMap.id);
          const details = new MapContainerDetails(
            layers,
            storedCards,
            settings.enableNavigationLayerToggle.isToggledOn
          );

          return { selectedMap, details };
        }),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr))
      )
      .subscribe((map: SelectedMapData) => {
        this.mapSelected.next(map);
      });
  }

  private subscribeToMapForChanges(): void {
    this.mapSelected$
      .pipe(
        filterUndefined(),
        filter(m => m.selectedMap.id !== EMPTY_GUID),
        filter(m => !this.currentMap || this.currentMap.selectedMap !== m.selectedMap)
      )
      .subscribe((map: SelectedMapData) => {
        this.currentMap = map;

        this.mapChanged.next(this.currentMap);
      });
  }
  // #endregion

  // #region Layer Changes
  selectPoiStatusChanged(): Observable<PoiDto> {
    return this.actions$.pipe(
      ofType(fromMaps.layerPoiStatusUpdate),
      mergeMap(p => this.poisStore.select(fromPois.selectPoiById(p.id))),
      filterUndefined(),
      takeUntil(this.ngUnsubscribeLiveData)
    );
  }

  selectPoiChanged(): Observable<PoiDto> {
    return this.actions$.pipe(
      ofType(fromMaps.layerPoiUpdate),
      mergeMap(p => this.poisStore.select(fromPois.selectPoiById(p.id))),
      distinctUntilChanged(),
      filterUndefined(),
      takeUntil(this.ngUnsubscribeLiveData)
    );
  }

  selectPoiCreated(): Observable<PoiDto> {
    return this.actions$.pipe(
      ofType(fromMaps.layerPoiCreated),
      mergeMap(p => this.poisStore.select(fromPois.selectPoiById(p.id))),
      filterUndefined(),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribeLiveData)
    );
  }

  selectPoiDeleted(): Observable<GuidString> {
    return this.actions$.pipe(
      ofType(fromMaps.layerPoiDeleted),
      filterUndefined(),
      map(poi => poi.id),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribeLiveData)
    );
  }
  // #endregion

  // #region Vehicles
  private subscribeToLiveMapData(): void {
    this.getLiveVehicleData()
      .pipe(takeUntil(this.ngUnsubscribeLiveData))
      .subscribe(vehicles => {
        this.allVehicles.next(vehicles);
      });

    this.rootStore
      .pipe(select(fromMaps.selectCollisionsByMapId))
      .pipe(takeUntil(this.ngUnsubscribeLiveData))
      .subscribe(allCollisionPoints => {
        this.allCollisionPoints.next(allCollisionPoints);
      });

    this.rootStore
      .pipe(select(fromMaps.selectIntersectionCollisionsByMapId))
      .pipe(takeUntil(this.ngUnsubscribeLiveData))
      .subscribe(allIntersectionCollisionPoints => {
        this.allIntersectionCollisionPoints.next(allIntersectionCollisionPoints);
      });
  }

  private getLiveVehicleData(): Observable<MergedVehicle[]> {
    let state: MergedVehicle[] = [];

    return this.rootStore.pipe(
      select(fromVehicles.selectVehiclesLoaded),
      filter(it => it),
      switchMap(_ => this.rootStore.pipe(select(fromVehicles.selectActiveVehiclesBySelectedMap))),
      map(it => setVehicleDefaultValues(it)),
      switchMap(vehicles => {
        const prevState = new Map(state.map(it => [it.id, it]));
        state = vehicles.map(v => this.setStateToVehicle(prevState, v));

        return this.vehiclePollingSignalRService.vehicleLocationUpdate.pipe(
          map(update => {
            return setUpdateToVehicles(state, update);
          }),
          startWith(state),
          tap(it => {
            state = it;
          })
        );
      })
    );
  }

  private setStateToVehicle(
    state: Map<GuidString, MergedVehicle>,
    vehicle: MergedVehicle
  ): MergedVehicle {
    const sv = state.get(vehicle.id);

    return {
      ...vehicle,
      pose2D: sv?.pose2D ?? vehicle.pose2D,
      path: sv?.path ?? vehicle.path,
      isLoaded: sv?.isLoaded ?? vehicle.isLoaded,
      hasError: sv?.hasError ?? vehicle.hasError,
      isPaused: sv?.isPaused ?? vehicle.isPaused,
      trailers: sv?.trailers ?? vehicle.trailers,
      maintenanceModeEnabled: sv?.maintenanceModeEnabled ?? vehicle.maintenanceModeEnabled,
      isConnected: sv?.isConnected ?? vehicle.isConnected,
      isSwitchedOff: sv?.isSwitchedOff ?? vehicle.isSwitchedOff,
      isBusy: sv?.isBusy ?? vehicle.isBusy,
      isCharging: sv?.isCharging ?? vehicle.isCharging,
      isRetired: sv?.isRetired ?? vehicle.isRetired,
    };
  }
  // #endregion

  // #region Traffic Analysis
  private async setDataForTrafficAnalysis(): Promise<void> {
    this.rootStore.dispatch(fromSettings.loadTrafficSettings());
    await this.vehiclePollingSignalRService.leaveVehicleLocationsMapGroup();
    await this.signalRNextService.leaveAllGroups();

    this.allVehicles.next([]);
    this.allCollisionPoints.next([]);
    this.allIntersectionCollisionPoints.next([]);

    this.subscribeToConflictDetails();
    this.subscribeToZoneAccessDetails();
  }

  private subscribeToZoneAccessDetails(): void {
    this.mapCommunicationService.zoneAccessDetails$
      .pipe(takeUntil(this.ngUnsubscribeTrafficAnalysis))
      .subscribe(details => {
        if (details.accessDetails) {
          void this.getVehiclesForTrafficAnalysis(details);
        } else {
          this.allVehicles.next([]);
        }
      });
  }

  private subscribeToConflictDetails(): void {
    this.mapCommunicationService.trafficConflictDetails$
      .pipe(takeUntil(this.ngUnsubscribeTrafficAnalysis))
      .subscribe(details => {
        if (details.conflictDetails) {
          this.allCollisionPoints.next([mapAdxRecordToCollision(details.conflictDetails)]);
          void this.getVehiclesForTrafficAnalysis(details);
        } else {
          this.allCollisionPoints.next([]);
          this.allVehicles.next([]);
        }
      });
  }

  async getVehiclesForTrafficAnalysis(details: {
    selectedWorkArea: WorkingArea | undefined;
    time: string;
  }): Promise<void> {
    if (details.selectedWorkArea) {
      const mapId = await firstValueFrom(this.mapId$);

      let res = await this.dataPublisherService.GetVehicleMessages(
        details.selectedWorkArea.organizationName,
        details.selectedWorkArea.name,
        details.time,
        mapId ?? ''
      );
      res = res.filter(v => v.mapId === mapId);

      this.allVehicles.next(
        res.map(
          (v: AdxVehicleMessageRecord): MergedVehicle => ({
            workAreaId: details.selectedWorkArea?.id ?? '',
            availability: VehicleAvailability.Available,
            status: VehicleStatus.Busy,
            batteryLevel: 50,
            batteryLevelStatus: BatteryLevelStatus.Orange,
            isErrorForwardingEnabled: true,
            hasError: false,
            isConnected: true,
            isSwitchedOff: false,
            initializationDateTime: '',
            isRetired: false,
            brakeTestRequired: false,
            softwareVersion: '',
            softwareVersionChangedDateUtc: '',
            softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
            softwareDownloadPercentage: 0,
            lastStateMessageProcessedUtc: '',
            velocity: { vx: 0, vy: 0, omega: 0 },
            zoneSetId: '',
            desiredZoneSetId: '',
            internalIdentifier: '',
            ipAddress: '',
            interfaceType: VehicleInterfaceType.Ros,
            fleetId: null,
            vehicleKey: '123456789',
            map: {
              id: v.mapId,
              navigationLayerId: '',
            },
            forkLength: 0,
            trailers: null,
            hardwareVersion: HardwareVersion.Unknown,
            vehicleConflictAreaDimensions: {
              workAreaId: details.selectedWorkArea?.id ?? '',
              vehicleId: v.id,
              lookAheadArea: v.lookaheadArea ?? null,
              deadlockArea: v.deadlockArea ?? null,
              stoppingArea: v.stoppingArea ?? null,
            },
            supportedLoadTypes: [],
            loadType: LoadType.Unknown,
            ...v,
          })
        )
      );
    }
  }

  // #endregion

  // #region Graph Layout
  setGraphLayout(layout: GraphLayout): void {
    this.graphLayout.next(layout);
  }
  // #endregion
}
