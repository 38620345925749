import { FeatureToggle } from 'core/dtos';
import { DateString, ErrorHandlingOptions } from '../../../models';
import { OrderConfirmationOption } from '../../jobs/mission-trace-abort-options.dto';

export interface MissionErrorHandlingDefaultsDto {
  confirmAbortToSap: boolean;
  confirmAbortToSapAs: OrderConfirmationOption;
  releaseInterlock: boolean;
  defaultErrorHandlingOption: ErrorHandlingOptions;
  dateUpdated?: DateString;
}

export interface MissionErrorHandlingDefaultsResponseModel extends MissionErrorHandlingDefaultsDto {
  dateUpdated: DateString;
}

export type MissionErrorHandlingDefaultsRequestModel = MissionErrorHandlingDefaultsDto;

export type EndOfShiftModeDto = FeatureToggle;
export type ParkingAndChargingDto = FeatureToggle;
export type WaitOnPoiDto = FeatureToggle;
export type AmaSettingsDto = FeatureToggle;
export type WaitingQueueSettingsDto = FeatureToggle;
export type MatrixOptimizationDto = FeatureToggle;
export type BrakeTestDto = FeatureToggle;

export interface JobManagerFeatures {
  endOfShiftModeToggle: EndOfShiftModeDto;
  parkingAndChargingModeToggle: ParkingAndChargingDto;
  waitOnPoiToggle: WaitOnPoiDto;
  amaSettingModeToggle: AmaSettingsDto;
  waitingQueueModeToggle: WaitingQueueSettingsDto;
  matrixOptimizationToggle: MatrixOptimizationDto;
  brakeTestToggle: BrakeTestDto;
  missionErrorHandlingDefaultsToggle: MissionErrorHandlingDefaultsDto;
}

export function getDefaultJobManagerFeatures(): JobManagerFeatures {
  return {
    endOfShiftModeToggle: { isToggledOn: false, dateUpdated: null },
    parkingAndChargingModeToggle: { isToggledOn: false, dateUpdated: null },
    waitOnPoiToggle: { isToggledOn: false, dateUpdated: null },
    amaSettingModeToggle: { isToggledOn: false, dateUpdated: null },
    waitingQueueModeToggle: { isToggledOn: false, dateUpdated: null },
    matrixOptimizationToggle: {
      isToggledOn: false,
      dateUpdated: null,
    },
    brakeTestToggle: { isToggledOn: false, dateUpdated: null },
    missionErrorHandlingDefaultsToggle: getMissionErrorHandlingDefaultsDto(),
  };
}

export function getMissionErrorHandlingDefaultsDto(): MissionErrorHandlingDefaultsDto {
  return {
    confirmAbortToSap: true,
    releaseInterlock: true,
    confirmAbortToSapAs: OrderConfirmationOption.SendAsConfirmation,
    defaultErrorHandlingOption: ErrorHandlingOptions.Abort,
  };
}
