/* eslint-disable max-lines */
import { createAction, props } from '@ngrx/store';
import {
  AmaSettingsDto,
  AvoidConflictsAtPoisDto,
  BackwardDrivingByPathDto,
  BrakeTestDto,
  CollectivePauseDto,
  CollectivePauseUpdateDto,
  EmulatorHostFeaturesDto,
  EnableNavigationLayerDto,
  EnableVehicleSmoothingSettingsDto,
  EndOfShiftModeDto,
  EvacModeToggleFireFighterGetResponse,
  EvacModeToggleFireFighterPutResponse,
  EvacuationDeviceDto,
  EvacuationModeDto,
  EvacuationModeToggleFireFighterUpdateRequest,
  FeatureToggle,
  FleetManagerFeatures,
  GraphManagerSettingsDto,
  IncludeTuggerTrainErrorsDto,
  InfobarMessageResponse,
  IpstServiceFeatures,
  JobManagerFeatures,
  MapManagerFeatures,
  MatrixOptimizationDto,
  MissionErrorHandlingDefaultsRequestModel,
  MissionErrorHandlingDefaultsResponseModel,
  NewIpstToggleDto,
  OrganizationDto,
  ParkingAndChargingDto,
  ShutdownModeDto,
  ShutdownRequestDto,
  TrafficManagementSettings,
  TrafficManagementSettingsDto,
  WaitOnPoiDto,
  WaitingQueueSettingsDto,
} from 'core/dtos';
import {
  OrderGatewayFeatures,
  PktInterfaceToggleDto,
} from 'core/dtos/settings/order-gateway-features.dto';

import { DateString, GuidString } from 'core/models';

export enum SettingsTypes {
  LoadFleetSettings = '[Settings] Load Fleet Settings',
  LoadFleetSettingsSuccess = '[Settings] Load Fleet Settings Success',
  LoadFleetSettingsFailure = '[Settings] Load Fleet Settings Fail',

  LoadIpstSettings = '[Settings] Load IPST Settings',
  LoadIpstSettingsSuccess = '[Settings] Load IPST Settings Success',
  LoadIpstSettingsFailure = '[Settings] Load IPST Settings Fail',

  LoadOrderGatewaySettings = '[Settings] Load Order Gateway Settings',
  LoadOrderGatewaySettingsSuccess = '[Settings] Load Order Gateway Settings Success',
  LoadOrderGatewaySettingsFailure = '[Settings] Load Order Gateway Settings Failure',

  LoadShutdownRequest = '[Settings] Load Shutdown Request',
  LoadShutdownRequestSuccess = '[Settings] Load Shutdown Request Success',
  LoadShutdownRequestFailure = '[Settings] Load Shutdown Request Fail',

  LoadInfobarMessage = '[Settings] Load Infobar Message',
  LoadInfobarMessageSuccess = '[Settings] Load Infobar Message Success',
  LoadInfobarMessageFailure = '[Settings] Load Infobar Message Fail',

  LoadTrafficSettings = '[Settings] Load Traffic Settings',
  LoadTrafficSettingsFailure = '[Settings] Load Traffic Settings Fail',
  LoadTrafficSettingsSuccess = '[Settings] Load Traffic Settings Success',

  LoadEmulatorSettings = '[Settings] Load Emulator Settings',
  LoadEmulatorSettingsSuccess = '[Settings] Load Emulator Settings Success',
  LoadEmulatorSettingsFailure = '[Settings] Load Emulator Settings Fail',

  LoadEvacuationDeviceSettings = '[Settings] Load EvacuationDevice Settings',
  LoadEvacuationDeviceSettingsSuccess = '[Settings] Load EvacuationDevice Settings Success',
  LoadEvacuationDeviceSettingsFailure = '[Settings] Load EvacuationDevice Settings Fail',

  LoadJobSettings = '[Settings] Load Job Settings',
  LoadJobSettingsSuccess = '[Settings] Load Job Settings Success',
  LoadJobSettingsFailure = '[Settings] Load Job Settings Fail',

  LoadMapSettings = '[Settings] Load Map Settings',
  LoadMapSettingsSuccess = '[Settings] Load Map Settings Success',
  LoadMapSettingsFailure = '[Settings] Load Map Settings Fail',

  ActivateEmulators = '[Dev Settings] Activate Emulators',
  ActivateEmulatorsSuccess = '[Dev Settings] Activate Emulators Success',
  ActivateEmulatorsFailure = '[Dev Settings] Activate Emulators Failure',

  DeactivateEmulators = '[Dev Settings] Deactivate Emulators',
  DeactivateEmulatorsSuccess = '[Dev Settings] Deactivate Emulators Success',
  DeactivateEmulatorsFailure = '[Dev Settings] Deactivate Emulators Failure',

  UpdateEmulatorSettings = '[Dev Settings] Edit Emulator Settings',
  UpdateEmulatorSettingsSuccess = '[Dev Settings] Edit Emulator Settings Success',
  UpdateEmulatorSettingsFailure = '[Dev Settings]  Edit Emulator Settings Failure',

  ToggleShutDownMode = '[Settings] Toggle ShutDownMode',
  ToggleShutDownModeSuccess = '[Settings] Toggle ShutDownMode Success',
  ToggleShutDownModeFailure = '[Settings] Toggle ShutDownMode Failure',

  ToggleEvacuationModeWorkingArea = '[Settings] Toggle EvacuationMode',
  ToggleEvacuationModeWorkingAreaSuccess = '[Settings] Toggle EvacuationMode Success',
  ToggleEvacuationModeWorkingAreaFailure = '[Settings] Toggle EvacuationMode Failure',

  ToggleEvacuationModeFireFighter = '[Settings] Toggle EvacuationMode Fire Fighter',
  ToggleEvacuationModeFireFighterSuccess = '[Settings] Toggle EvacuationMode Fire Fighter Success',
  ToggleEvacuationModeFireFighterFailure = '[Settings] Toggle EvacuationMode Fire Fighter Failure',

  RetrieveAllFireFighterSettings = '[Settings] Retrieve EvacuationMode Fire Fighter',
  RetrieveAllFireFighterSettingsSuccess = '[Settings] Retrieve EvacuationMode Fire Fighter Success',
  RetrieveAllFireFighterSettingsFailure = '[Settings] Retrieve EvacuationMode Fire Fighter Failure',

  ToggleCollectivePause = '[Settings] Toggle CollectivePause',
  ToggleCollectivePauseSuccess = '[Settings] Toggle CollectivePause Success',
  ToggleCollectivePauseFailure = '[Settings] Toggle CollectivePause Failure',

  UpdateEvacuationDeviceSettings = '[Dev Settings] Edit EvacuationDevice Settings',
  UpdateEvacuationDeviceSettingsSuccess = '[Dev Settings] Edit EvacuationDevice Settings Success',
  UpdateEvacuationDeviceSettingsFailure = '[Dev Settings]  Edit EvacuationDevice Settings Failure',

  ToggleEndOfShiftMode = '[Settings] Toggle EndOfShift',
  ToggleEndOfShiftModeSuccess = '[Settings] Toggle EndOfShift Success',
  ToggleEndOfShiftModeFailure = '[Settings] Toggle EndOfShift Failure',

  ToggleBeginShiftMode = '[Settings] Toggle BeginShiftMode',
  ToggleBeginShiftModeSuccess = '[Settings] Toggle BeginShiftMode Success',
  ToggleBeginShiftModeFailure = '[Settings] Toggle BeginShiftMode Failure',

  ToggleMatrixOptimization = '[Settings] Toggle MatrixOptimization',
  ToggleMatrixOptimizationSuccess = '[Settings] Toggle MatrixOptimization Success',
  ToggleMatrixOptimizationFailure = '[Settings] Toggle MatrixOptimization Failure',

  ToggleBrakeTest = '[Settings] Toggle BrakeTest',
  ToggleBrakeTestSuccess = '[Settings] Toggle BrakeTest Success',
  ToggleBrakeTestFailure = '[Settings] Toggle BrakeTest Failure',

  ToggleIncludeTuggerTrainErrors = '[Settings] Toggle IncludeTuggerTrainErrors',
  ToggleIncludeTuggerTrainErrorsSuccess = '[Settings] Toggle IncludeTuggerTrainErrors Success',
  ToggleIncludeTuggerTrainErrorsFailure = '[Settings] Toggle IncludeTuggerTrainErrors Failure',

  ToggleNewIpstToggle = '[Settings] Toggle NewIpstToggle',
  ToggleNewIpstToggleSuccess = '[Settings] Toggle NewIpstToggle Success',
  ToggleNewIpstToggleFailure = '[Settings] Toggle NewIpstToggle Failure',

  ToggleEnableNavigationLayers = '[Settings] Toggle EnableNavigationLayers',
  ToggleEnableNavigationLayersSuccess = '[Settings] Toggle EnableNavigationLayers Success',
  ToggleEnableNavigationLayersFailure = '[Settings] Toggle EnableNavigationLayers Failure',

  ToggleParkingAndCharging = '[Settings] Toggle Parking and Charging',
  ToggleParkingAndChargingSuccess = '[Settings] Toggle Parking and Charging Success',
  ToggleParkingAndChargingFailure = '[Settings] Toggle Parking and Charging Failure',

  ToggleWaitOnPoi = '[Settings] Toggle Wait on PoI',
  ToggleWaitOnPoiSuccess = '[Settings] Toggle Wait on PoI Success',
  ToggleWaitOnPoiFailure = '[Settings] Toggle Wait on PoI Failure',

  ToggleAmaSettings = '[Settings] Toggle Ama Settings',
  ToggleAmaSettingsSuccess = '[Settings] Toggle Ama Settings Success',
  ToggleAmaSettingsFailure = '[Settings] Toggle Ama Settings Failure',

  ToggleWaitingQueueSettings = '[Settings] Toggle Waiting Queue Settings',
  ToggleWaitingQueueSettingsSuccess = '[Settings] Toggle Waiting Queue Settings Success',
  ToggleWaitingQueueSettingsFailure = '[Settings] Toggle Waiting Queue Settings Failure',

  ToggleTrafficSettings = '[Settings] Toggle Traffic Settings',
  ToggleTrafficSettingsSuccess = '[Settings] Toggle Traffic Settings Success',
  ToggleTrafficSettingsFailure = '[Settings] Toggle Traffic Settings Failure',

  ToggleBackwardDrivingPathBased = '[Settings] Toggle Backward Driving Path Based Settings',
  ToggleBackwardDrivingPathBasedSuccess = '[Settings] Toggle Backward Driving Path Based Settings Success',
  ToggleBackwardDrivingPathBasedFailure = '[Settings] Toggle Backward Driving Path Based Settings Failure',

  ToggleAvoidConflictAtPois = '[Settings] Toggle Avoid Conflict At Pois',
  ToggleAvoidConflictAtPoisSuccess = '[Settings] Toggle Avoid Conflict At Pois Success',
  ToggleAvoidConflictAtPoisFailure = '[Settings] Toggle Avoid Conflict At Pois Failure',

  TogglePktInterface = '[Settings] Toggle Pkt Interface',
  TogglePktInterfaceSuccess = '[Settings] Toggle Pkt Interface Success',
  TogglePktInterfaceFailure = '[Settings] Toggle Pkt Interface Failure',

  UpdateMissionErrorHandlingSettings = '[Settings] Edit Mission Error Handling Settings',
  UpdateMissionErrorHandlingSettingsSuccess = '[Settings] Edit Mission Error Handling Settings Success',
  UpdateMissionErrorHandlingSettingsFailure = '[Settings] Edit Mission Error Handling Settings Failure',

  LoadGraphManagerSettings = '[Settings] Load Graph Manager Settings',
  LoadGraphManagerSettingsSuccess = '[Settings] Load Graph ManagerSettings Success',
  LoadGraphManagerSettingsFailure = '[Settings] Load Graph Manager Settings Fail',

  ToggleEnableGraphManager = '[Settings] Toggle Enable Graph Manager',
  ToggleEnableGraphManagerSuccess = '[Settings] Toggle Enable Graph Manager Success',
  ToggleEnableGraphManagerFailure = '[Settings] Toggle Enable Graph Manager Failure',

  RestartGraphManager = '[Settings] Restart Graph Manager',
  RestartGraphManagerSuccess = '[Settings] Restart Graph Manager Success',
  RestartGraphManagerFailure = '[Settings] Restart Graph Manager Failure',

  ToggleEnableVehicleSmoothing = '[Settings] Toggle Enable Vehicle Smoothing',
  ToggleEnableVehicleSmoothingSuccess = '[Settings] Toggle Enable Vehicle Smoothing Success',
  ToggleEnableVehicleSmoothingFailure = '[Settings] Toggle Enable Vehicle Smoothing Failure',

  LoadIpstLastMessageResolve = '[Settings] Load Last Message Resolve',
  LoadIpstLastMessageResolveSuccess = '[Settings] Load Last Message Resolve Success',
  LoadIpstLastMessageResolveFailure = '[Settings] Load Last Message Resolve Failure',

  ResolveAllIpstMessages = '[Settings] Resolve All IPST Messages',
  ResolveAllIpstMessagesSuccess = '[Settings] Resolve All IPST Messages Success',
  ResolveAllIpstMessagesFailure = '[Settings] Resolve All IPST Messages Failure',
}

export const loadFleetSettings = createAction(SettingsTypes.LoadFleetSettings);

export const loadFleetSettingsSuccess = createAction(
  SettingsTypes.LoadFleetSettingsSuccess,
  props<{ fleetManagerFeatures: FleetManagerFeatures }>()
);

export const loadFleetSettingsFailure = createAction(
  SettingsTypes.LoadFleetSettingsFailure,
  props<{ errorMessage: string }>()
);

export const loadIpstSettings = createAction(SettingsTypes.LoadIpstSettings);

export const loadIpstSettingsSuccess = createAction(
  SettingsTypes.LoadIpstSettingsSuccess,
  props<{ ipstServiceFeatures: IpstServiceFeatures }>()
);

export const loadIpstSettingsFailure = createAction(
  SettingsTypes.LoadIpstSettingsFailure,
  props<{ errorMessage: string }>()
);

export const loadOrderGatewaySettings = createAction(SettingsTypes.LoadOrderGatewaySettings);

export const loadOrderGatewaySettingsSuccess = createAction(
  SettingsTypes.LoadOrderGatewaySettingsSuccess,
  props<{ orderGatewayFeatures: OrderGatewayFeatures }>()
);

export const loadOrderGatewaySettingsFailure = createAction(
  SettingsTypes.LoadOrderGatewaySettingsFailure,
  props<{ errorMessage: string }>()
);

export const loadInfobarMessage = createAction(SettingsTypes.LoadInfobarMessage);

export const loadInfobarMessageSuccess = createAction(
  SettingsTypes.LoadInfobarMessageSuccess,
  props<{ infobarMessage: InfobarMessageResponse | null }>()
);

export const loadInfobarMessageFailure = createAction(
  SettingsTypes.LoadInfobarMessageFailure,
  props<{ errorMessage: string }>()
);

export const loadShutdownRequest = createAction(SettingsTypes.LoadShutdownRequest);

export const loadShutdownRequestSuccess = createAction(
  SettingsTypes.LoadShutdownRequestSuccess,
  props<{ shutdownRequest: ShutdownRequestDto[] }>()
);

export const loadShutdownRequestFailure = createAction(
  SettingsTypes.LoadShutdownRequestFailure,
  props<{ errorMessage: string }>()
);

export const loadTrafficSettings = createAction(SettingsTypes.LoadTrafficSettings);

export const loadTrafficSettingsSuccess = createAction(
  SettingsTypes.LoadTrafficSettingsSuccess,
  props<{ trafficManagementSettings: TrafficManagementSettings }>()
);

export const loadTrafficSettingsFailure = createAction(
  SettingsTypes.LoadTrafficSettingsFailure,
  props<{ errorMessage: string }>()
);

export const loadEmulatorSettings = createAction(
  SettingsTypes.LoadEmulatorSettings,
  props<{ workAreaId: GuidString }>()
);

export const loadEmulatorSettingsSuccess = createAction(
  SettingsTypes.LoadEmulatorSettingsSuccess,
  props<{ emulatorManagerFeatures: EmulatorHostFeaturesDto }>()
);

export const loadEmulatorSettingsFailure = createAction(
  SettingsTypes.LoadEmulatorSettingsFailure,
  props<{ errorMessage: string }>()
);

export const loadEvacuationDeviceSettings = createAction(
  SettingsTypes.LoadEvacuationDeviceSettings
);

export const loadEvacuationDeviceSettingsSuccess = createAction(
  SettingsTypes.LoadEvacuationDeviceSettingsSuccess,
  props<{ devices: EvacuationDeviceDto[] }>()
);

export const loadEvacuationDeviceSettingsFailure = createAction(
  SettingsTypes.LoadEvacuationDeviceSettingsFailure,
  props<{ errorMessage: string }>()
);

export const loadJobSettings = createAction(SettingsTypes.LoadJobSettings);

export const loadJobSettingsSuccess = createAction(
  SettingsTypes.LoadJobSettingsSuccess,
  props<{ jobManagerFeatures: JobManagerFeatures }>()
);

export const loadJobSettingsFailure = createAction(
  SettingsTypes.LoadJobSettingsFailure,
  props<{ errorMessage: string }>()
);

export const loadMapSettings = createAction(SettingsTypes.LoadMapSettings);

export const loadMapSettingsSuccess = createAction(
  SettingsTypes.LoadMapSettingsSuccess,
  props<{ mapManagerFeatures: MapManagerFeatures }>()
);

export const loadMapSettingsFailure = createAction(
  SettingsTypes.LoadMapSettingsFailure,
  props<{ errorMessage: string }>()
);

export const activateEmulators = createAction(
  SettingsTypes.ActivateEmulators,
  props<{ workingAreaId: GuidString }>()
);

export const activateEmulatorsSuccess = createAction(SettingsTypes.ActivateEmulatorsSuccess);

export const activateEmulatorsFailure = createAction(
  SettingsTypes.ActivateEmulatorsFailure,
  props<{ errorMessage: string }>()
);

export const deactivateEmulators = createAction(
  SettingsTypes.DeactivateEmulators,
  props<{ workingAreaId: GuidString }>()
);

export const deactivateEmulatorsSuccess = createAction(SettingsTypes.DeactivateEmulatorsSuccess);

export const deactivateEmulatorsFailure = createAction(
  SettingsTypes.DeactivateEmulatorsFailure,
  props<{ errorMessage: string }>()
);

export const updateEmulatorSettings = createAction(
  SettingsTypes.UpdateEmulatorSettings,
  props<{ emulatorFeature: EmulatorHostFeaturesDto; workAreaId: GuidString }>()
);

export const updateEmulatorSettingsSuccess = createAction(
  SettingsTypes.UpdateEmulatorSettingsSuccess,
  props<{ emulatorFeature: EmulatorHostFeaturesDto }>()
);

export const updateEmulatorSettingsFailure = createAction(
  SettingsTypes.UpdateEmulatorSettingsFailure,
  props<{ errorMessage: string }>()
);

export const toggleShutDownMode = createAction(
  SettingsTypes.ToggleShutDownMode,
  props<{ toggle: ShutdownModeDto }>()
);

export const toggleShutDownModeSuccess = createAction(
  SettingsTypes.ToggleShutDownModeSuccess,
  props<{ result: ShutdownModeDto }>()
);

export const toggleShutDownModeFailure = createAction(
  SettingsTypes.ToggleShutDownModeFailure,
  props<{ errorMessage: string }>()
);

export const toggleEvacuationModeWorkingArea = createAction(
  SettingsTypes.ToggleEvacuationModeWorkingArea,
  props<{ value: boolean }>()
);

export const toggleEvacuationModeWorkingAreaSuccess = createAction(
  SettingsTypes.ToggleEvacuationModeWorkingAreaSuccess,
  props<{ result: EvacuationModeDto }>()
);

export const toggleEvacuationModeWorkingAreaFailure = createAction(
  SettingsTypes.ToggleEvacuationModeWorkingAreaFailure,
  props<{ errorMessage: string }>()
);

export const toggleEvacuationModeFireFighter = createAction(
  SettingsTypes.ToggleEvacuationModeFireFighter,
  props<{ value: EvacuationModeToggleFireFighterUpdateRequest }>()
);

export const toggleEvacuationModeFireFighterSuccess = createAction(
  SettingsTypes.ToggleEvacuationModeFireFighterSuccess,
  props<{ result: EvacModeToggleFireFighterPutResponse }>()
);

export const toggleEvacuationModeFireFighterFailure = createAction(
  SettingsTypes.ToggleEvacuationModeFireFighterFailure,
  props<{ errorMessage: string }>()
);

export const retrieveAllFireFighterSettings = createAction(
  SettingsTypes.RetrieveAllFireFighterSettings
);

export const retrieveAllFireFighterSettingsSuccess = createAction(
  SettingsTypes.RetrieveAllFireFighterSettingsSuccess,
  props<{ result: EvacModeToggleFireFighterGetResponse[] }>()
);

export const retrieveAllFireFighterSettingsFailure = createAction(
  SettingsTypes.RetrieveAllFireFighterSettingsFailure,
  props<{ errorMessage: string }>()
);

export const toggleCollectivePause = createAction(
  SettingsTypes.ToggleCollectivePause,
  props<{ toggle: CollectivePauseUpdateDto }>()
);

export const toggleCollectivePauseSuccess = createAction(
  SettingsTypes.ToggleCollectivePauseSuccess,
  props<{ result: CollectivePauseDto }>()
);

export const toggleCollectivePauseFailure = createAction(
  SettingsTypes.ToggleCollectivePauseFailure,
  props<{ errorMessage: string }>()
);

export const updateEvacuationDeviceSettings = createAction(
  SettingsTypes.UpdateEvacuationDeviceSettings,
  props<{ device: EvacuationDeviceDto }>()
);

export const updateEvacuationDeviceSettingsSuccess = createAction(
  SettingsTypes.UpdateEvacuationDeviceSettingsSuccess,
  props<{ device: EvacuationDeviceDto }>()
);

export const updateEvacuationDeviceSettingsFailure = createAction(
  SettingsTypes.UpdateEvacuationDeviceSettingsFailure,
  props<{ errorMessage: string }>()
);

export const toggleEndOfShiftMode = createAction(
  SettingsTypes.ToggleEndOfShiftMode,
  props<{ toggle: boolean }>()
);

export const toggleEndOfShiftModeSuccess = createAction(
  SettingsTypes.ToggleEndOfShiftModeSuccess,
  props<{ result: EndOfShiftModeDto }>()
);

export const toggleEndOfShiftModeFailure = createAction(
  SettingsTypes.ToggleEndOfShiftModeFailure,
  props<{ errorMessage: string }>()
);

export const toggleParkingAndCharging = createAction(
  SettingsTypes.ToggleParkingAndCharging,
  props<{ value: boolean }>()
);

export const toggleParkingAndChargingSuccess = createAction(
  SettingsTypes.ToggleParkingAndChargingSuccess,
  props<{ result: ParkingAndChargingDto }>()
);

export const toggleParkingAndChargingFailure = createAction(
  SettingsTypes.ToggleParkingAndChargingFailure,
  props<{ errorMessage: string }>()
);

export const toggleWaitOnPoi = createAction(
  SettingsTypes.ToggleWaitOnPoi,
  props<{ value: boolean }>()
);

export const toggleWaitOnPoiSuccess = createAction(
  SettingsTypes.ToggleWaitOnPoiSuccess,
  props<{ result: WaitOnPoiDto }>()
);

export const toggleWaitOnPoiFailure = createAction(
  SettingsTypes.ToggleWaitOnPoiFailure,
  props<{ errorMessage: string }>()
);

export const toggleAmaSettings = createAction(
  SettingsTypes.ToggleAmaSettings,
  props<{ value: boolean }>()
);

export const toggleAmaSettingsSuccess = createAction(
  SettingsTypes.ToggleAmaSettingsSuccess,
  props<{ result: AmaSettingsDto }>()
);

export const toggleAmaSettingsFailure = createAction(
  SettingsTypes.ToggleAmaSettingsFailure,
  props<{ errorMessage: string }>()
);

export const togglePktInterface = createAction(
  SettingsTypes.TogglePktInterface,
  props<{ value: boolean }>()
);

export const togglePktInterfaceSuccess = createAction(
  SettingsTypes.TogglePktInterfaceSuccess,
  props<{ result: PktInterfaceToggleDto }>()
);

export const togglePktInterfaceFailure = createAction(
  SettingsTypes.TogglePktInterfaceFailure,
  props<{ errorMessage: string }>()
);

export const toggleWaitingQueueSettings = createAction(
  SettingsTypes.ToggleWaitingQueueSettings,
  props<{ value: boolean }>()
);

export const toggleWaitingQueueSettingsSuccess = createAction(
  SettingsTypes.ToggleWaitingQueueSettingsSuccess,
  props<{ result: WaitingQueueSettingsDto }>()
);

export const toggleWaitingQueueSettingsFailure = createAction(
  SettingsTypes.ToggleWaitingQueueSettingsFailure,
  props<{ errorMessage: string }>()
);

export const toggleTrafficSettings = createAction(
  SettingsTypes.ToggleTrafficSettings,
  props<{ value: boolean }>()
);

export const toggleTrafficSettingsSuccess = createAction(
  SettingsTypes.ToggleTrafficSettingsSuccess,
  props<{ result: TrafficManagementSettingsDto }>()
);

export const toggleTrafficSettingsFailure = createAction(
  SettingsTypes.ToggleTrafficSettingsFailure,
  props<{ errorMessage: string }>()
);

export const toggleBackwardDrivingPathBased = createAction(
  SettingsTypes.ToggleBackwardDrivingPathBased,
  props<{ value: boolean }>()
);

export const toggleBackwardDrivingPathBasedSuccess = createAction(
  SettingsTypes.ToggleBackwardDrivingPathBasedSuccess,
  props<{ result: BackwardDrivingByPathDto }>()
);

export const toggleBackwardDrivingPathBasedFailure = createAction(
  SettingsTypes.ToggleBackwardDrivingPathBasedFailure,
  props<{ errorMessage: string }>()
);

export const toggleAvoidConflictAtPois = createAction(
  SettingsTypes.ToggleAvoidConflictAtPois,
  props<{ value: boolean }>()
);

export const toggleAvoidConflictAtPoisSuccess = createAction(
  SettingsTypes.ToggleAvoidConflictAtPoisSuccess,
  props<{ result: AvoidConflictsAtPoisDto }>()
);

export const toggleAvoidConflictAtPoisFailure = createAction(
  SettingsTypes.ToggleAvoidConflictAtPoisFailure,
  props<{ errorMessage: string }>()
);

export const toggleMatrixOptimization = createAction(
  SettingsTypes.ToggleMatrixOptimization,
  props<{ toggle: boolean }>()
);

export const toggleMatrixOptimizationSuccess = createAction(
  SettingsTypes.ToggleMatrixOptimizationSuccess,
  props<{ result: MatrixOptimizationDto }>()
);

export const toggleMatrixOptimizationFailure = createAction(
  SettingsTypes.ToggleMatrixOptimizationFailure,
  props<{ errorMessage: string }>()
);

export const toggleBrakeTest = createAction(
  SettingsTypes.ToggleBrakeTest,
  props<{ toggle: boolean }>()
);

export const toggleBrakeTestSuccess = createAction(
  SettingsTypes.ToggleBrakeTestSuccess,
  props<{ result: BrakeTestDto }>()
);

export const toggleBrakeTestFailure = createAction(
  SettingsTypes.ToggleBrakeTestFailure,
  props<{ errorMessage: string }>()
);

export const toggleIncludeTuggerTrainErrors = createAction(
  SettingsTypes.ToggleIncludeTuggerTrainErrors,
  props<{ toggle: boolean }>()
);

export const toggleIncludeTuggerTrainErrorsSuccess = createAction(
  SettingsTypes.ToggleIncludeTuggerTrainErrorsSuccess,
  props<{ result: IncludeTuggerTrainErrorsDto }>()
);

export const toggleIncludeTuggerTrainErrorsFailure = createAction(
  SettingsTypes.ToggleIncludeTuggerTrainErrorsFailure,
  props<{ errorMessage: string }>()
);

export const toggleNewIpstToggle = createAction(
  SettingsTypes.ToggleNewIpstToggle,
  props<{ toggle: boolean }>()
);

export const toggleNewIpstToggleSuccess = createAction(
  SettingsTypes.ToggleNewIpstToggleSuccess,
  props<{ result: NewIpstToggleDto }>()
);

export const toggleNewIpstToggleFailure = createAction(
  SettingsTypes.ToggleNewIpstToggleFailure,
  props<{ errorMessage: string }>()
);

export const toggleEnableNavigationLayers = createAction(
  SettingsTypes.ToggleEnableNavigationLayers,
  props<{ toggle: boolean }>()
);

export const toggleEnableNavigationLayersSuccess = createAction(
  SettingsTypes.ToggleEnableNavigationLayersSuccess,
  props<{ result: EnableNavigationLayerDto }>()
);

export const toggleEnableNavigationLayersFailure = createAction(
  SettingsTypes.ToggleEnableNavigationLayersFailure,
  props<{ errorMessage: string }>()
);

export const toggleEnableVehicleSmoothing = createAction(
  SettingsTypes.ToggleEnableVehicleSmoothing,
  props<{ toggle: boolean }>()
);

export const toggleEnableVehicleSmoothingSuccess = createAction(
  SettingsTypes.ToggleEnableVehicleSmoothingSuccess,
  props<{ result: EnableVehicleSmoothingSettingsDto }>()
);

export const toggleEnableVehicleSmoothingFailure = createAction(
  SettingsTypes.ToggleEnableVehicleSmoothingFailure,
  props<{ errorMessage: string }>()
);

export const updateMissionErrorHandlingSettings = createAction(
  SettingsTypes.UpdateMissionErrorHandlingSettings,
  props<{ settings: MissionErrorHandlingDefaultsRequestModel }>()
);

export const updateMissionErrorHandlingSettingsSuccess = createAction(
  SettingsTypes.UpdateMissionErrorHandlingSettingsSuccess,
  props<{ result: MissionErrorHandlingDefaultsResponseModel }>()
);

export const updateMissionErrorHandlingSettingsFailure = createAction(
  SettingsTypes.UpdateMissionErrorHandlingSettingsFailure,
  props<{ errorMessage: string }>()
);

export const loadGraphManagerSettings = createAction(SettingsTypes.LoadGraphManagerSettings);

export const loadGraphManagerSettingsSuccess = createAction(
  SettingsTypes.LoadGraphManagerSettingsSuccess,
  props<{ graphManagerFeatures: GraphManagerSettingsDto }>()
);

export const loadGraphManagerSettingsFailure = createAction(
  SettingsTypes.LoadGraphManagerSettingsFailure,
  props<{ errorMessage: string }>()
);

export const toggleEnableGraphManager = createAction(
  SettingsTypes.ToggleEnableGraphManager,
  props<{ enableGraphManager: boolean }>()
);

export const toggleEnableGraphManagerSuccess = createAction(
  SettingsTypes.ToggleEnableGraphManagerSuccess,
  props<{ graphManagerFeatures: FeatureToggle }>()
);

export const toggleEnableGraphManagerFailure = createAction(
  SettingsTypes.ToggleEnableGraphManagerFailure,
  props<{ errorMessage: string }>()
);

export const toggleBeginShiftMode = createAction(
  SettingsTypes.ToggleBeginShiftMode,
  props<{ beginShiftMode: boolean }>()
);

export const toggleBeginShiftModeSuccess = createAction(
  SettingsTypes.ToggleBeginShiftModeSuccess,
  props<{ graphManagerFeatures: FeatureToggle }>()
);

export const toggleBeginShiftModeFailure = createAction(
  SettingsTypes.ToggleBeginShiftModeFailure,
  props<{ errorMessage: string }>()
);

export const restartGraphManager = createAction(
  SettingsTypes.RestartGraphManager,
  props<{ restartGraphManager: boolean }>()
);

export const restartGraphManagerSuccess = createAction(
  SettingsTypes.RestartGraphManagerSuccess,
  props<{ restartGraphManager: boolean }>()
);

export const restartGraphManagerFailure = createAction(
  SettingsTypes.RestartGraphManagerFailure,
  props<{ errorMessage: string }>()
);

export const loadLastIpstMessageResolve = createAction(
  SettingsTypes.LoadIpstLastMessageResolve,
  props<{ workAreaId: GuidString }>()
);

export const loadLastIpstMessageResolveSuccess = createAction(
  SettingsTypes.LoadIpstLastMessageResolveSuccess,
  props<{ lastMessageResolve: DateString }>()
);

export const loadLastIpstMessageResolveFailure = createAction(
  SettingsTypes.LoadIpstLastMessageResolveFailure,
  props<{ errorMessage: string }>()
);

export const resolveAllIpstMessages = createAction(
  SettingsTypes.ResolveAllIpstMessages,
  props<{ workAreaId: GuidString; org: OrganizationDto }>()
);

export const resolveAllIpstMessagesSuccess = createAction(
  SettingsTypes.ResolveAllIpstMessagesSuccess
);

export const resolveAllIpstMessagesFailure = createAction(
  SettingsTypes.ResolveAllIpstMessagesFailure,
  props<{ errorMessage: string }>()
);
