/* eslint-disable max-lines */
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { EMPTY_GUID } from 'core/constants';
import { VehicleDto, VehicleListSignalrDto, VehicleMapAssociation } from 'core/dtos';
import { roundBatteryLevel } from 'core/helpers/vehicles.helper';
import { GuidString } from 'core/models';
import {
  unpackMessageMetrics,
  unpackOrderState,
} from 'core/signalR/helpers/unpack-functions.helper';
import { isEqual } from 'lodash';
import * as VehicleActions from '../actions/vehicles.actions';
import { mapVehicleMapNavigation } from '../selectors/vehicles.selectors.helpers';

const idKey = 'id';

export const featureKey = 'vehicles';

export interface VehiclesState extends EntityState<VehicleDto> {
  loading: boolean;
  loaded: boolean;
  selectedVehicleId: GuidString;
  errorMessage: string;
}

export const vehiclesAdapter: EntityAdapter<VehicleDto> = createEntityAdapter<VehicleDto>();

export const initialState: VehiclesState = vehiclesAdapter.getInitialState({
  loading: false,
  loaded: false,
  selectedVehicleId: '',
  errorMessage: '',
});

export const vehiclesReducer = createReducer(
  initialState,
  on(VehicleActions.loadVehicles, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(VehicleActions.loadVehiclesSuccess, (state, { vehicles }) =>
    vehiclesAdapter.setAll(vehicles, {
      ...state,
      loading: false,
      loaded: true,
    })
  ),
  on(VehicleActions.loadVehiclesFailure, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    loaded: false,
    errorMessage,
  })),
  on(
    VehicleActions.addVehicleSuccess,
    VehicleActions.addEmulatorVehicleToStoreSuccess,
    VehicleActions.signalRAddVehicleSuccess,
    (state, { vehicleCreated }) =>
      vehiclesAdapter.addOne(vehicleCreated, { ...state, loading: false })
  ),
  on(VehicleActions.signalRDeleteVehicleMapAssociation, (state, { vehicleMapAssociation }) => {
    const vehicleId = vehicleMapAssociation.vehicleId.toString();
    const storedVehicle = state.entities[vehicleId] as VehicleDto;

    if (storedVehicle?.map?.id === vehicleMapAssociation.mapId) {
      return updateMapAssociation(state, { ...vehicleMapAssociation, mapId: EMPTY_GUID });
    }
    return state;
  }),
  on(
    VehicleActions.signalRAddVehicleMapAssociation,
    VehicleActions.signalRUpdateVehicleMapAssociation,
    (state, { vehicleMapAssociation }) => {
      return updateMapAssociation(state, vehicleMapAssociation);
    }
  ),
  on(VehicleActions.deleteVehicleSuccess, (state, { vehicle }) =>
    vehiclesAdapter.removeOne(vehicle.id.toString(), { ...state, loading: false })
  ),
  on(VehicleActions.deleteVehiclesByFleetId, (state, { fleetId }) =>
    vehiclesAdapter.removeMany(v => v.fleetId === fleetId, { ...state, loading: false })
  ),
  on(
    VehicleActions.ungroupVehicleSuccess,
    VehicleActions.updateVehicleSuccess,
    VehicleActions.updateMaintenanceModeSuccess,
    VehicleActions.updateErrorForwardingSuccess,
    VehicleActions.signalRVehicleDetailsDeleteVehicle,
    (state, { vehicle }) => vehiclesAdapter.updateOne(vehicle, { ...state, loading: false })
  ),
  on(VehicleActions.massUpdateMaintenanceModeSuccess, (state, { vehicleIds }) => {
    return vehiclesAdapter.updateMany(
      vehicleIds.map(id => updateVehicle(id)),
      { ...state }
    );
  }),
  on(VehicleActions.signalRDeleteVehicle, (state, { vehicle }) => {
    const storedVehicle = state.entities[vehicle[idKey].toString()];
    if (storedVehicle) {
      return vehiclesAdapter.removeOne(storedVehicle.id.toString(), state);
    }
    return state;
  }),
  on(VehicleActions.signalRUpdateVehicleLocation, (state, { vehicleLocation }) => {
    const storedVehicle = state.entities[vehicleLocation[idKey].toString()];
    if (storedVehicle) {
      const updatedVehicle: VehicleDto = Object.assign({}, storedVehicle, vehicleLocation);

      return vehiclesAdapter.updateOne(
        {
          id: updatedVehicle.id.toString(),
          changes: updatedVehicle,
        },
        state
      );
    }

    return state;
  }),
  on(VehicleActions.signalRUpdateVehicle, (state, { vehicle }) => {
    const storedVehicle: VehicleDto | undefined = state.entities[vehicle[idKey].toString()];
    if (!storedVehicle) {
      return state;
    }

    const updatedVehicle: VehicleDto = Object.assign({}, storedVehicle, vehicle);

    updatedVehicle.batteryLevel = roundBatteryLevel(updatedVehicle.batteryLevel);
    updatedVehicle.path = updatedVehicle.path || [];
    if (updatedVehicle.map) {
      updatedVehicle.map = { ...updatedVehicle.map, id: vehicle.mapId };
    }

    if (!isEqual(storedVehicle, updatedVehicle)) {
      return vehiclesAdapter.updateOne(
        {
          id: updatedVehicle.id.toString(),
          changes: updatedVehicle,
        },
        state
      );
    }
    return state;
  }),

  on(VehicleActions.vehicleListMessagesReceived, (state, { vehicleMessages }) => {
    return vehiclesAdapter.updateMany(
      vehicleMessages.map(vehicle => updateVehicleListDetails(vehicle)),
      { ...state }
    );
  }),

  on(VehicleActions.updateZoneSetSuccess, (state, { vehicleId, zoneSetId }) => {
    return vehiclesAdapter.map(
      vehicle => (vehicle.id === vehicleId ? { ...vehicle, desiredZoneSetId: zoneSetId } : vehicle),
      { ...state, loading: false }
    );
  }),
  on(VehicleActions.signalRVehicleWaiting, (state, { message }) => {
    return vehiclesAdapter.map(
      vehicle =>
        vehicle.id === message.vehicleId
          ? { ...vehicle, waitEndTime: message.waitEndTime }
          : vehicle,
      { ...state, loading: false }
    );
  }),
  on(VehicleActions.updateMapSuccess, (state, { vehicleId, mapId }) => {
    return vehiclesAdapter.map(
      vehicle => {
        const result: VehicleDto =
          vehicle.id === vehicleId ? { ...vehicle, map: mapVehicleMapNavigation(mapId) } : vehicle;

        return result;
      },
      { ...state, loading: false }
    );
  }),
  on(VehicleActions.localizeVehicleSuccess, (state, { vehicleId, pose }) =>
    vehiclesAdapter.map(
      vehicle => (vehicle.id === vehicleId ? { ...vehicle, pose2D: pose } : vehicle),
      { ...state, loading: false }
    )
  ),
  on(VehicleActions.selectVehicle, (state, { vehicleId }) => ({
    ...state,
    selectedVehicleId: vehicleId,
  })),
  on(VehicleActions.updateFleetIds, (state, { fleetId: id, vehicles: vehicles }) => {
    const vehicleIds = vehicles.map(vehicle => vehicle.id);

    return vehiclesAdapter.map(vehicle => {
      if (vehicle.fleetId === id && !vehicleIds.includes(vehicle.id)) {
        return { ...vehicle, fleetId: null };
      }
      return vehicleIds.includes(vehicle.id) ? { ...vehicle, fleetId: id } : vehicle;
    }, state);
  }),
  on(VehicleActions.ungroupVehiclesByFleetId, (state, { fleetId }) =>
    vehiclesAdapter.map(
      vehicle => (vehicle.fleetId === fleetId ? { ...vehicle, fleetId: null } : vehicle),
      { ...state, loading: false }
    )
  ),
  on(
    VehicleActions.addVehicle,
    VehicleActions.deleteVehicle,
    VehicleActions.deleteEmulatorVehicle,
    VehicleActions.ungroupVehicle,
    VehicleActions.updateVehicle,
    VehicleActions.updateMap,
    VehicleActions.updateZoneSet,
    VehicleActions.localizeVehicle,
    VehicleActions.updateMaintenanceMode,
    VehicleActions.updateErrorForwarding,
    VehicleActions.pauseVehicle,
    VehicleActions.resumeVehicle,
    VehicleActions.requestFactsheet,
    VehicleActions.addEmulatorVehicle,
    VehicleActions.updateEmulatorVehicle,
    VehicleActions.setEmulatorMode,
    VehicleActions.toggleEmulatorPath,
    VehicleActions.triggerEmulatorError,
    VehicleActions.triggerClearEmulatorError,
    (state: VehiclesState) => ({
      ...state,
      loading: true,
      errorMessage: '',
    })
  ),
  on(
    VehicleActions.addVehicleFailure,
    VehicleActions.deleteVehicleFailure,
    VehicleActions.updateVehicleFailure,
    VehicleActions.ungroupVehicleFailure,
    VehicleActions.updateMapFailure,
    VehicleActions.updateZoneSetFailure,
    VehicleActions.localizeVehicleFailure,
    VehicleActions.updateMaintenanceModeFailure,
    VehicleActions.updateErrorForwardingFailure,
    VehicleActions.pauseVehicleFailure,
    VehicleActions.resumeVehicleFailure,
    VehicleActions.requestFactsheetFailure,
    VehicleActions.setEmulatorModeFailure,
    VehicleActions.toggleEmulatorPathFailure,
    VehicleActions.triggerEmulatorErrorFailure,
    VehicleActions.triggerClearEmulatorErrorFailure,
    (state, { errorMessage }) => ({
      ...state,
      loading: false,
      errorMessage,
    })
  ),
  on(
    VehicleActions.addEmulatorVehicleFailure,
    VehicleActions.updateEmulatorVehicleFailure,
    (state, { errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    })
  ),
  on(
    VehicleActions.addEmulatorVehicleSuccess,
    VehicleActions.updateEmulatorVehicleSuccess,
    VehicleActions.requestFactsheetSuccess,
    state => ({
      ...state,
      loading: false,
    })
  ),

  on(VehicleActions.pauseVehicleSuccess, (state, { vehicleId }) =>
    vehiclesAdapter.updateOne(
      {
        id: vehicleId.toString(),
        changes: { isPaused: true },
      },
      state
    )
  ),

  on(VehicleActions.resumeVehicleSuccess, (state, { vehicleId }) =>
    vehiclesAdapter.updateOne(
      {
        id: vehicleId.toString(),
        changes: { isPaused: false },
      },
      state
    )
  ),

  on(VehicleActions.resetEmulator, state => ({
    ...state,
    loading: true,
  })),
  on(VehicleActions.resetEmulatorSuccess, VehicleActions.resetEmulatorFailure, state => ({
    ...state,
    loading: false,
  })),

  on(VehicleActions.setEmulatorModeSuccess, (state, { vehicleId, mode }) => {
    return vehiclesAdapter.map(
      vehicle => (vehicle.id === vehicleId ? { ...vehicle, mode } : vehicle),
      { ...state, loading: false }
    );
  }),

  on(VehicleActions.toggleEmulatorPathSuccess, (state, { vehicleId, isRealPathActive }) => {
    return vehiclesAdapter.map(
      vehicle => (vehicle.id === vehicleId ? { ...vehicle, isRealPathActive } : vehicle),
      { ...state, loading: false }
    );
  }),

  on(VehicleActions.triggerEmulatorErrorSuccess, (state, { vehicleId }) => {
    return vehiclesAdapter.map(vehicle => (vehicle.id === vehicleId ? { ...vehicle } : vehicle), {
      ...state,
      loading: false,
    });
  }),

  on(VehicleActions.triggerClearEmulatorErrorSuccess, (state, { vehicleId }) => {
    return vehiclesAdapter.map(vehicle => (vehicle.id === vehicleId ? { ...vehicle } : vehicle), {
      ...state,
      loading: false,
    });
  })
);

export function reducer(state: VehiclesState | undefined, action: Action): VehiclesState {
  return vehiclesReducer(state, action);
}

export const { selectEntities, selectAll } = vehiclesAdapter.getSelectors();

export const getLoading = (state: VehiclesState): boolean => (state || initialState).loading;
export const getLoaded = (state: VehiclesState): boolean => (state || initialState).loaded;
export const getSelectedVehicleId = (state: VehiclesState): GuidString =>
  (state || initialState).selectedVehicleId;
export const getErrorMessage = (state: VehiclesState): string =>
  (state || initialState).errorMessage;
export const getEntities = selectEntities;
export const getVehicles = selectAll;

const updateVehicleListDetails = (vehicle: VehicleListSignalrDto): Update<VehicleDto> => {
  return {
    id: vehicle.id.toString(),
    changes: {
      maintenanceModeEnabled: vehicle.maintenanceModeEnabled,
      workAreaId: vehicle.workAreaId,
      batteryLevel: vehicle.batteryLevel,
      batteryLevelStatus: vehicle.batteryLevelStatus,
      fleetId: vehicle.fleetId,
      fleetName: vehicle.fleetName ?? undefined,
      ipAddress: vehicle.ipAddress,
      lastStateMessageProcessedUtc: vehicle.lastStateMessageProcessedUtc.toISOString(),
      internalIdentifier: vehicle.internalIdentifier,
      name: vehicle.name,
      hasError: vehicle.hasError,
      isPaused: vehicle.isPaused,
      isCharging: vehicle.isCharging,
      isBusy: vehicle.isBusy,
      isRetired: vehicle.isRetired,
      isSwitchedOff: vehicle.isSwitchedOff,
      isConnected: vehicle.isConnected,
      isLoaded: vehicle.isLoaded,
      vehicleType: vehicle.vehicleType,
      mode: vehicle.mode,
      zoneSetId: vehicle.zoneSetId,
      desiredZoneSetId: vehicle.desiredZoneSetId,
      softwareVersion: vehicle.softwareVersion,
      softwareVersionChangedDateUtc: vehicle.softwareVersionChangedDateUtc?.toISOString(),
      softwareUpdateStatus: vehicle.softwareUpdateStatus,
      softwareDownloadPercentage: vehicle.softwareDownloadPercentage,
      availability: vehicle.availability,
      status: vehicle.status,
      metrics: unpackMessageMetrics(vehicle.metrics),
      orderState: unpackOrderState(vehicle.orderState),
      supportedLoadTypes: vehicle.supportedLoadTypes,
    },
  };
};

const updateVehicle = (vehicleId: GuidString): Update<VehicleDto> => {
  return {
    id: vehicleId.toString(),
    changes: {
      maintenanceModeEnabled: true,
    },
  };
};

const updateMapAssociation = (
  state: VehiclesState,
  vehicleMapAssociation: VehicleMapAssociation
) => {
  const vehicleId = vehicleMapAssociation.vehicleId.toString();
  const storedVehicle: VehicleDto | undefined = state.entities[vehicleId];
  if (
    !storedVehicle ||
    !storedVehicle.map ||
    storedVehicle.map.id === vehicleMapAssociation.mapId
  ) {
    return state;
  }
  return vehiclesAdapter.updateOne(
    {
      id: vehicleId,
      changes: {
        map: {
          ...storedVehicle.map,
          id: vehicleMapAssociation.mapId,
        },
      },
    },
    state
  );
};
