export enum VehicleAvailability {
  NotAvailable = 0,
  Available = 1,
}

export enum VehicleMode {
  Autonomous = 0,
  Manual = 1,
}

export enum VehicleStatus {
  NotInitialized = 0,
  Busy = 1,
  Idle = 2,
  Charging = 3,
  Paused = 4,
  Error = 5,
  NotConnected = 6,
  SwitchedOff = 7,
}

export const InactiveVehicleStates = [
  VehicleStatus.NotConnected,
  VehicleStatus.NotInitialized,
  VehicleStatus.SwitchedOff,
];

export enum VehicleLoadStatus {
  NotLoaded = 0,
  Loaded = 1,
}

export enum VehicleMaintenanceStatus {
  NotInMaintenance = 0,
  Maintenance = 1,
}

export enum VehiclesMenuButtons {
  NewFleet = 0,
  NewVehicle = 1,
  ActionWakeVehicles = 2,
  ActionSendZone = 3,
  ActionSetMaintenanceMode = 4,
  ActionSendSoftwareUpdate = 5,
}

export enum VehicleInterfaceType {
  Ros = 0,
  Vda5050 = 1,
}

export enum VehicleTrafficLightStatus {
  Connected = 0,
  SwitchedOff = 1,
  NotInitialized = 2,
  ConnectedWithError = 3,
  LostConnection = 4,
}

export enum VehicleInformation {
  Paused = 0,
  Maintenance = 1,
  Charging = 2,
  Loaded = 3,
  BrakeTestRequired = 4,
}

export enum BatteryLevelStatus {
  Green = 0,
  Orange = 1,
  Red = 2,
}

export enum HardwareVersion {
  Unknown = 0,
  StrVersion2 = 2,
  StrVersion3 = 3,
  StrVersion4 = 4,
  TuggerTrainFourAmV2 = 5,
  TuggerTrainDsV1 = 6,
  ForkliftFourAm_15t = 7,
  ForkliftFourAm_20t = 8,
  DSType1 = 9,
  DSType2 = 10,
  DSType3 = 11,
  DSType4 = 12,
  DSType5 = 13,
  TuggerTrainManual = 14,
  ForkliftManual = 15,
}

export enum VehicleType {
  UnitLoad = 0,
  TuggerTrain = 1,
  Forklift = 2,
  U_AGV = 4,
}

export enum LoadType {
  Unknown = 0,
  Glt1400X1250 = 1,
  Uglt1680X1250 = 2,
  MSpa1260x1160 = 3,
  SeatDeb1000x1600 = 4,
  GltPd1200x1000 = 5,
  GltPd800x1200 = 6,
}
export interface LoadDimensions {
  length: number;
  width: number;
}

export const LoadTypeDimensions: Record<LoadType, LoadDimensions> = {
  [LoadType.Unknown]: { length: 1200, width: 800 },
  [LoadType.Glt1400X1250]: { length: 1200, width: 800 },
  [LoadType.Uglt1680X1250]: { length: 1200, width: 800 },
  [LoadType.MSpa1260x1160]: { length: 1350, width: 1160 },
  [LoadType.SeatDeb1000x1600]: { length: 1200, width: 1000 },
  [LoadType.GltPd1200x1000]: { length: 800, width: 1200 },
  [LoadType.GltPd800x1200]: { length: 1200, width: 800 },
};

export enum BrokerType {
  IoTHub = 0,
  MqttBroker = 1,
}

export enum SoftwareUpdateStatus {
  NoUpdate = 0,
  Downloading = 1,
  Installing = 2,
  Error = 3,
}

export enum SoftwareUpdateVersionStatus {
  Unknown = 0,
  Approved = 1,
  Outdated = 2,
  Test = 3,
}

export const HardwareVersionVehicleType = {
  [HardwareVersion.StrVersion2]: VehicleType.UnitLoad,
  [HardwareVersion.StrVersion3]: VehicleType.UnitLoad,
  [HardwareVersion.StrVersion4]: VehicleType.UnitLoad,
  [HardwareVersion.TuggerTrainFourAmV2]: VehicleType.TuggerTrain,
  [HardwareVersion.TuggerTrainDsV1]: VehicleType.TuggerTrain,
  [HardwareVersion.TuggerTrainManual]: VehicleType.TuggerTrain,
  [HardwareVersion.ForkliftFourAm_15t]: VehicleType.Forklift,
  [HardwareVersion.ForkliftFourAm_20t]: VehicleType.Forklift,
  [HardwareVersion.ForkliftManual]: VehicleType.Forklift,
  [HardwareVersion.DSType1]: VehicleType.U_AGV,
  [HardwareVersion.DSType2]: VehicleType.U_AGV,
  [HardwareVersion.DSType3]: VehicleType.U_AGV,
  [HardwareVersion.DSType4]: VehicleType.U_AGV,
  [HardwareVersion.DSType5]: VehicleType.U_AGV,
};

export const DefaultHardwareVersion: Record<VehicleType, HardwareVersion> = {
  [VehicleType.UnitLoad]: HardwareVersion.StrVersion3,
  [VehicleType.TuggerTrain]: HardwareVersion.TuggerTrainFourAmV2,
  [VehicleType.Forklift]: HardwareVersion.ForkliftFourAm_15t,
  [VehicleType.U_AGV]: HardwareVersion.DSType1,
};

export enum VehicleGroup {
  Unknown = 0,
  Str = 1,
  TuggerTrainFourAmV2 = 2,
  TuggerTrainDs = 3,
  USTR = 4,
  Forklift = 5,
  Manual = 6,
  U_AGV = 7,
}

export enum VehiclePowerSavingType {
  Str = 0,
  Vda5050 = 1,
}

export enum VehicleErrorType {
  AtsJob10 = 'ATS_JOB_10',
}
