import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DATE_FORMAT } from 'core/constants';
import {
  ExtendedDsMenuItem,
  MissionDeliveryStatus,
  MissionPanelListData,
  MissionStatus,
  VehicleStatus,
} from 'core/models';
import { AtsTranslationService } from 'core/services';
import { EnumTranslationPipe } from 'library/pipes/enum-translation.pipe';
import { formatDate } from 'shared/helpers';

@Component({
  selector: 'app-mission-cell',
  templateUrl: './mission-cell.component.html',
  styleUrls: ['./mission-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionCellComponent implements ICellRendererAngularComp {
  menuItems: ExtendedDsMenuItem[] = [];
  mission?: MissionPanelListData;
  isAborted = false;
  displayErrorIcon = false;
  displayCommentIcon = false;
  additionalInfo = '';
  private readonly enumTranslationPipe = new EnumTranslationPipe(this.atsTranslationService);

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly atsTranslationService: AtsTranslationService
  ) {}

  agInit(params: ICellRendererParams<MissionPanelListData>): void {
    this.refresh(params);
  }

  refresh(params: ICellRendererParams<MissionPanelListData>): boolean {
    this.mission = params.data;
    this.isAborted = this.mission?.missionStatus === MissionStatus.Aborted;
    this.setErrorIcon();
    this.setCommentIcon();
    this.additionalInfo = this.getAdditionalInfo();
    this.menuItems = this.mission?.menuItems ?? [];
    this.menuItems = this.mission?.isFinalState ? [] : this.mission?.menuItems ?? [];
    this.cdRef.markForCheck();
    return true;
  }

  setErrorIcon(): void {
    this.displayErrorIcon =
      (this.mission?.vehicleStatus === VehicleStatus.Error ||
        this.mission?.vehicleStatus === VehicleStatus.NotConnected) &&
      !this.mission.isFinalState;
  }

  setCommentIcon(): void {
    if (this.mission?.missionFailureReasonId) this.displayCommentIcon = true;
    else this.displayCommentIcon = false;
  }

  getAdditionalInfo(): string {
    if (this.mission && this.mission.missionStatus === MissionStatus.WaitingForAssignment) {
      return formatDate(this.mission.assignmentDelayEndDateTime, DATE_FORMAT.TIME_HOURS_MINUTES);
    }

    if (this.mission?.deliveryStatus === undefined) {
      return '';
    }

    switch (this.mission.deliveryStatus) {
      case MissionDeliveryStatus.Due:
      case MissionDeliveryStatus.DuePriority:
      case MissionDeliveryStatus.DueShortly:
      case MissionDeliveryStatus.DueShortlyPriority:
        return `- ${this.enumTranslationPipe.transform(
          MissionDeliveryStatus.Due,
          'DeliveryStatus'
        )} in ${this.mission?.minutesUntilDelivery} min`;
      case MissionDeliveryStatus.Overdue:
        return `- ${Math.abs(
          this.mission?.minutesUntilDelivery || 0
        )} min ${this.enumTranslationPipe
          .transform(this.mission?.deliveryStatus, 'DeliveryStatus')
          ?.toLowerCase()}`;
      default:
        return '';
    }
  }
}
