import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DsNavigationBarComponent } from '@bmw-ds/components';
import { DsNavigationItem } from '@bmw-ds/components/ds-interfaces/navigation-bar.interface';
import { environment } from '@environment';
import { Store, select } from '@ngrx/store';
import { OrganizationDto, WorkingAreaDto } from 'core/dtos';
import { UserManual, UserManualLinks, UserManualRouteNames } from 'core/models';
import { TenantRouterService } from 'core/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HeaderNavbarIntegrationService } from 'shared/services';
import * as fromSettings from 'store-modules/settings-store';

import * as fromRoot from 'store/index';

const ROUTING_WAIT = 100;

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidemenuComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DsNavigationBarComponent)
  navigationBar!: DsNavigationBarComponent;
  url: UserManual[] = UserManualLinks;
  pageUrl = '';
  defaultUrl: UserManual = {
    name: UserManualRouteNames.Default,
    route: 'https://handbook.ats.azure.cloud.bmw',
  };
  selectedWorkingAreaSettingsUrl = '';
  selectedSideMenuItemSettings = '';

  showOnIndependentFromWorkingArea = false;
  waIndependentRoutes = [
    'welcome',
    'settings/auditlog',
    'organization-settings',
    'settings/global-user-roles-permission/environment-users',
    '/settings/global-user-roles-permission/',
  ];
  ngUnsubscribe = new Subject<void>();

  activeItemId = '';
  previousActiveItemId = '';
  isExpanded = false;
  selectedWa?: WorkingAreaDto;
  selectedOrg?: OrganizationDto;
  showDeveloperOptions = environment.config.showDeveloperOptions;
  graphManagerEnabled = false;
  newIpstSettingsEnabled = false;

  constructor(
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly tenantRouter: TenantRouterService,
    private readonly router: Router,
    private readonly cdRef: ChangeDetectorRef,
    private readonly headerIntegrationService: HeaderNavbarIntegrationService,
    private readonly settingsStore: Store<fromSettings.SettingsFeatureState>
  ) {}

  ngAfterViewInit(): void {
    this.headerIntegrationService.navBar$.next(this.navigationBar);
    this.rootStore
      .pipe(select(fromRoot.getRouterInfo), takeUntil(this.ngUnsubscribe))
      .subscribe(routerInfo => {
        setTimeout(() => {
          this.activeItemId = routerInfo.data.selectedSideMenuItem;
          this.showOnIndependentFromWorkingArea = this.waIndependentRoutes.some(route =>
            routerInfo.url.includes(route)
          );
          this.cdRef.markForCheck();
        }, ROUTING_WAIT);
      });
  }

  ngOnInit(): void {
    this.rootStore
      .pipe(select(fromRoot.selectSelectedWorkingArea), takeUntil(this.ngUnsubscribe))
      .subscribe(wa => {
        this.selectedWa = wa;
        this.setWaSettingUrl();
      });

    this.rootStore
      .pipe(select(fromRoot.selectSelectedOrganization), takeUntil(this.ngUnsubscribe))
      .subscribe(org => {
        this.selectedOrg = org;
        this.setWaSettingUrl();
      });

    this.settingsStore
      .pipe(select(fromSettings.selectGraphManagerFeatureSettings), takeUntil(this.ngUnsubscribe))
      .subscribe(graphManagerSettings => {
        this.graphManagerEnabled = graphManagerSettings.settings.enableGraphManager ?? false;
        this.cdRef.markForCheck();
      });

    this.settingsStore
      .pipe(select(fromSettings.selectIpstFeatures), takeUntil(this.ngUnsubscribe))
      .subscribe(ipstSettings => {
        this.newIpstSettingsEnabled = ipstSettings.newIpstToggle.isToggledOn ?? false;
        this.cdRef.markForCheck();
      });
  }

  private setWaSettingUrl(): void {
    const waBase = 'settings/user-roles-permission/working-area-users';

    this.selectedWorkingAreaSettingsUrl = `${waBase}/${this.selectedOrg?.id}/${this.selectedWa?.id}`;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  isExpandedChanged(isExpanded: boolean): void {
    this.isExpanded = isExpanded;
  }

  onParentSelected(item: string): void {
    const isTouchDevice = window.matchMedia('(pointer: coarse)').matches;

    if (!this.isExpanded && !isTouchDevice) {
      const withReload =
        (this.previousActiveItemId === '' ? this.activeItemId : this.previousActiveItemId) === item;
      this.previousActiveItemId = item;
      void this.navigateToRoute(item, withReload);
    }
  }

  onItemSelected(items: DsNavigationItem[]): void {
    const lastItem = items[items.length - 1];
    const withReload =
      (this.previousActiveItemId === '' ? this.activeItemId : this.previousActiveItemId) ===
      lastItem?.id;
    this.previousActiveItemId = lastItem?.id || '';
    void this.navigateToRoute(lastItem?.data ?? lastItem?.routerLink, withReload);
  }

  async navigateToRoute(route: string, withReload = false): Promise<boolean> {
    if (this.waIndependentRoutes.includes(route)) {
      if (withReload) {
        return this.router
          .navigateByUrl('/reload', { skipLocationChange: true })
          .then(() => this.router.navigate([route]));
      } else {
        return this.router.navigate([route]);
      }
    } else {
      if (withReload) {
        return this.router
          .navigateByUrl('/reload', { skipLocationChange: true })
          .then(() => this.tenantRouter.navigate([route]));
      } else {
        return this.tenantRouter.navigate([route]);
      }
    }
  }
}
