import { VehicleDto } from 'core/dtos';
import { HardwareVersion, HardwareVersionVehicleType, VehicleGroup } from 'core/models';
import { DefaultVersionItems } from '../layers';

type ConfiguredMapHardwareVersions = Exclude<
  HardwareVersion,
  | HardwareVersion.Unknown
  | HardwareVersion.DSType1
  | HardwareVersion.DSType2
  | HardwareVersion.DSType3
  | HardwareVersion.DSType4
  | HardwareVersion.DSType4
  | HardwareVersion.DSType5
>;

const VehicleGroupByHardwareVersion: Record<ConfiguredMapHardwareVersions, VehicleGroup> = {
  [HardwareVersion.StrVersion2]: VehicleGroup.Str,
  [HardwareVersion.StrVersion3]: VehicleGroup.Str,
  [HardwareVersion.StrVersion4]: VehicleGroup.Str,
  [HardwareVersion.TuggerTrainFourAmV2]: VehicleGroup.TuggerTrainFourAmV2,
  [HardwareVersion.TuggerTrainDsV1]: VehicleGroup.TuggerTrainDs,
  [HardwareVersion.ForkliftFourAm_15t]: VehicleGroup.Forklift,
  [HardwareVersion.ForkliftFourAm_20t]: VehicleGroup.Forklift,
  [HardwareVersion.TuggerTrainManual]: VehicleGroup.Manual,
  [HardwareVersion.ForkliftManual]: VehicleGroup.Manual,
};

export class MapVehicleHelper {
  static getDefaultHardwareVersion(vehicle: VehicleDto): HardwareVersion {
    if (
      vehicle.hardwareVersion === undefined ||
      vehicle.hardwareVersion === HardwareVersion.Unknown ||
      HardwareVersionVehicleType[vehicle.hardwareVersion] !== vehicle.vehicleType
    ) {
      console.warn(`Vehicle ${vehicle.name} has unknown Hardware Version`);

      return DefaultVersionItems[vehicle.vehicleType];
    }

    return vehicle.hardwareVersion;
  }

  static getVehicleGroupByHardwareVersion(hardwareVersion: HardwareVersion): VehicleGroup {
    return VehicleGroupByHardwareVersion[hardwareVersion] ?? VehicleGroup.Unknown;
  }

  static compareHardwareVersionAndVehicleGroup(
    hardwareVersion: HardwareVersion,
    vehicleGroup: VehicleGroup
  ): boolean {
    return vehicleGroup === MapVehicleHelper.getVehicleGroupByHardwareVersion(hardwareVersion);
  }
}
