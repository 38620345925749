import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { JsonHubProtocol } from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { GuidString, SignalRNextMessage, Timestamped } from 'core/models';
import { SessionService } from 'core/services/session.service';
import { ToastService } from 'core/services/toast.service';
import { Subject } from 'rxjs';
import * as fromRoot from 'store/reducers';

import { MissionTraceDto } from 'core/dtos';
import { SignalRService } from './signalr.service';

@Injectable({
  providedIn: 'root',
})
export class SignalRNextService extends SignalRService {
  constructor(
    protected sessionService: SessionService,
    protected store: Store<fromRoot.RootState>,
    protected toastService: ToastService
  ) {
    super(sessionService, store, toastService, environment.Services.Signalr, new JsonHubProtocol());
  }

  registerConnectionNext<T>(methodName: string, callback: Subject<SignalRNextMessage<T>>): void {
    this.hubConnection.on(methodName, (data: SignalRNextMessage<T>) => {
      callback.next(data);
      this.logSignalRCall(methodName, data);
    });
  }

  async fetchChangedMissionTraces(
    missionTraceId: GuidString,
    fromTicks: string | null
  ): Promise<Timestamped<MissionTraceDto>[]> {
    await this.connect();
    if (!this.isConnectedOrTest()) return [];

    const missions = await this.hubConnection.invoke<[] | [Timestamped<MissionTraceDto>]>(
      'FetchChangedMissionTraces',
      missionTraceId,
      fromTicks
    );

    if (missions.length > 0) this.logSignalRPollingResult('FetchChangedMissionTraces', missions);

    return missions;
  }
}
